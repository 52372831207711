//////////////////////////////////////////
// Modals
//////////////////////////////////////////
.store-modal {
  .modal-dialog {
    @include media-breakpoint-down(lg) {
      margin: 0;
      width: 100%;
      min-height: 100%;
      max-width: 100%;
    }

    .modal-content {
      padding: 0 20px 20px;
      height: 100%;
      border: 0;

      @include media-breakpoint-down(lg) {
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.9) 0%,
          rgba(228, 228, 228, 0.9) 100%
        );
        backdrop-filter: blur(35px);
      }

      @include media-breakpoint-up(xl) {
        padding: 20px;
        border-radius: 20px;
      }

      .modal-title {
        margin: 28px 0;
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        @include media-breakpoint-up(xl) {
          margin-top: 0;
          margin-bottom: 35px;
          text-align: left;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
        }

        .rtl & {
          @include media-breakpoint-up(xl) {
            text-align: right;
          }
        }
      }

      .btn-close-modal {
        display: none;
        @include media-breakpoint-up(xl) {
          display: block;
          position: absolute;
          top: 10px;
          right: 10px;
          width: 46px;
          height: 46px;
          z-index: 1;
        }

        .rtl & {
          @include media-breakpoint-up(xl) {
            right: auto;
            left: 10px;
          }
        }

        img {
          width: 100%;
        }
      }

      .btn-close-modal-mobile {
        display: block;
        position: absolute;
        top: 24px;
        left: 20px;
        z-index: 1;
        width: 38px;
        height: 38px;
        @include media-breakpoint-up(xl) {
          display: none;
        }

        .rtl & {
          left: 0;
          right: 20px;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}
