//////////////////////////////////////////
// Checkout mobile
//////////////////////////////////////////

.checkout-btn {
  position: absolute;
  bottom: 0;
  height: 6vh !important;
}

.checkout-btn,
.checkout-head {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 50px;
  border-radius: 10px 10px 0 0 !important;

  &.disabled,
  &:disabled {
    background-color: #b0b5bf;
  }

  .iconly-Buy {
    margin: 0 22px 0 -3px;
    font-size: 24px;

    .rtl & {
      margin: 0 18px 0 22px;
    }
  }
  .checkout-header {
    margin-left: -10px;
  }

  h3 {
    margin-bottom: 0;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    flex-grow: 1;
    text-align: left;
    text-transform: uppercase;
    .rtl & {
      text-align: right;
    }
  }

  .start-coupon {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 48px;
    height: 32px;
    z-index: 1;
    color: #fff;
    background-image: url('/assets/images/ticket.svg');
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;

    .value {
      font-weight: 600;
      font-size: 15px;
      line-height: 1;
    }
  }

  .seperator {
    margin: 0 15px;
    width: 1px;
    height: 32px;
    background-color: #fff;
  }

  .total-sum {
    margin-right: 15px;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    .rtl & {
      margin-right: unset;
      margin-left: 15px;
    }
  }
}

app-mobile-checkout {
  ng-scrollbar {
    max-height: 100%;
  }
  ng-container > ng-scrollbar {
    max-height: 80vh;
  }
  .checkout-warper {
    display: flex;
    flex-direction: column;
    padding: 0px 4% 0 4%;
    .checkout-container {
      flex: 1 1 100%;
      padding-left: 4%;
      padding-right: 4%;

      .bordered-container {
        padding-bottom: 15px;

        .rtl & {
          padding-left: unset;
          border-left: unset;
        }
      }

      .title {
        display: flex;
        align-items: baseline;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 15px;

        .rtl & {
          padding-left: unset;
          padding-right: 30px;
        }

        app-icon {
          transform: translate(-20%, 0);
          .rtl & {
            margin-left: unset;
            margin-right: -30px;
            transform: translate(50%, 0);
          }
        }
      }

      ngx-stripe-card-group {
        display: block;
        margin-top: 20px;
        .custom-input {
          margin-bottom: 10px;
        }
      }

      .input-wrapper {
        display: inline-table;
        margin-bottom: 10px;
        width: 100%;
        height: 50px;
        padding: 16px;
        border-radius: 10px;
        border: 0;
        background-color: #f6f7fb;
        &.half {
          width: calc(50% - 5px);
          &.with-margin {
            margin-left: 10px;

            .rtl & {
              margin-left: unset;
              margin-right: 10px;
            }
          }
        }

        ng-container > ng-scrollbar {
          max-height: 80vh;
        }
        .checkout-warper {
          display: flex;
          flex-direction: column;
          .checkout-container {
            flex: 1 1 100%;
            padding-left: 30px;
            padding-right: 30px;

            .bordered-container {
              padding-bottom: 15px;
              padding-left: 30px;
              border-left: 2px dotted #fff;
              @media (max-width: 326px) {
                width: 94%;
                padding-left: 20px;
              }
              .rtl & {
                padding-left: unset;
                padding-right: 30px;
                border-left: unset;
                border-right: 2px dotted #fff;
              }
            }
          }
        }
      }

      //////////////////////////////////////////
      // Order checkout
      //////////////////////////////////////////
      .order-checkout-summary {
        margin: 0 -20px 10px;
        padding: 20px;
        background-color: #ffffff;
        border-radius: 15px;

        @media (max-width: 330px) {
          width: 104%;
        }

        .order-totals-table {
          .order-totals-row {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 32px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;

            &.order-totals-summary {
              margin-top: 10px;
              border-top: 1px solid rgba(#9aa4ad, 0.3);
              font-weight: 700;
            }

            &.deposit-row {
              color: #3bd984;
            }

            .row-title {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
            }

            .value {
              &.discount {
                color: var(--secondary-store);
              }
            }
          }
        }

        .order-confirm-container {
          .legal-text {
            font-weight: 500;
            font-size: 12px;
            line-height: 22px;
          }

          .btn-confirm {
            width: 100%;
          }
        }
      }
      &.show-iframe {
        .order-checkout-summary {
          .order-confirm-container {
            display: none;
          }
        }
      }
    }
    .checkout-iframe-container {
      flex: 1 1 100%;
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      iframe {
        border: 0;
        width: 100%;
        height: calc(var(--vh, 1vh) * 100); //cardcom height
      }
    }
  }
}
