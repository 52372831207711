.store-products {
  height: 80%;
  h2 {
    font-weight: 600;
    font-size: 34px;
  }
  img {
    max-width: 100%;
  }
  .products-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    .block-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;

      .add-new-item {
        padding: 12px 28px 12px 20px;
        border-radius: 60px;
        background-color: #000000;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
        text-decoration: none;
      }

      .import-items {
        padding: 14px 20px 14px 20px;
        border-radius: 60px;
        background-color: #f39449;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
        text-decoration: none;
        cursor: pointer;
      }
    }

    button {
      margin-left: 10px;

      .rtl & {
        margin-left: unset;
        margin-right: 10px;
      }
    }
  }
  .table-height {
    height: calc((var(--vh, 1vh) * 100) - 300px);
  }
  .items-sync-data {
    display: flex;
    align-items: center;

    app-icon {
      margin-right: 8px;
    }
  }
  .products-table {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    .products-row {
      display: flex;
      padding-top: 10px;
      border-bottom: 1px solid #00000021;

      &.products-headline {
        div {
          color: #b3b3b3;
        }
      }

      div {
        font-size: 16px;
        font-weight: 900;
        margin-left: 20px;
        .rtl & {
          margin-left: unset;
          margin-right: 20px;
        }

        &:nth-child(1) {
          flex: 0.15;
          margin-left: 0;
          overflow: hidden;
          .rtl & {
            margin-left: unset;
            margin-right: 0;
          }
        }
        &:nth-child(2) {
          flex: 0.2;
        }
        &:nth-child(3) {
          flex: 0.25;
        }
        &:nth-child(4) {
          flex: 0.1;
        }
        &:nth-child(5) {
          flex: 0.1;
        }
        &:nth-child(6) {
          flex: 0.1;
        }
        &:nth-child(7) {
          flex: 0.1;
        }
      }
      .product-name {
        font-weight: 500;
      }
      .product-description,
      .product-price,
      .product-images {
        font-weight: 500;
        font-size: 12px;
      }

      .product-images {
        flex-wrap: wrap;
        img {
          width: 60px;
          height: 60px;
          border-radius: 13px;
          margin: 0 20px 20px 0;
          .rtl & {
            margin: 0 0 20px 20px;
          }
        }
      }
      .product-actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        .edit-button {
          margin-bottom: 10px;
        }
      }
    }
  }
}
