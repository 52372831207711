@import 'scss/style.scss';
@import 'ngx-toastr/toastr-bs4-alert';
@import '~codemirror/lib/codemirror.css';

/* Importing Bootstrap SCSS file. */
//@import '~bootstrap/scss/bootstrap';

// for some reason, this is the only way to get the ng-select to work
// styles in the page scss are not affecting the ng-select
.ng-select-container {
  height: 40px !important;
}