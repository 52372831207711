.profile-details-modal {
  .modal-dialog {
    .modal-content {
      app-profile-details-modal {
        .content {
          margin: 0 auto;
          width: 280px;

          .avatar-container {
            margin: 0 auto 20px;
            width: 154px;
            height: 154px;
            background-color: #fff;
            background-image: url('/assets/images/avatar-placeholder.svg');
            background-size: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            border-radius: 50%;
            overflow: hidden;

            .profile-picture {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }

          .btn-profile-picture {
            margin-bottom: 45px;
            width: 100%;
            color: var(--primary-store);
            background: #f8f8f8;
          }

          //////////////////////////////////////////
          // Profile Details
          //////////////////////////////////////////
          .profile-details {
            .btn-edit {
              margin-top: 15px;
              margin-bottom: 15px;
              width: 100%;
            }
          }

          //////////////////////////////////////////
          // Form
          //////////////////////////////////////////
          .col-form-label {
            padding-bottom: 0;
            font-size: 18px;
            letter-spacing: -0.03em;
            color: #b0b5bf;
          }

          .calendar {
            padding: 4px 8px 0;
            i {
              color: var(--primary-store);
            }
          }

          .btn-save {
            margin-top: 15px;
            margin-bottom: 15px;
            width: 100%;
          }
        }
      }
    }
  }
}
