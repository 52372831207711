button {
  padding: 0;
  border: 0;
  background-color: transparent;
}

.btn-base {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  height: 40px;
  color: var(--primary-store);
  font-size: 17px;
  font-weight: 600;
  line-height: 1;
  border: 0;
  box-shadow: none;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  border-radius: 10px;
  background-color: #ffffff;

  &:hover {
    text-decoration: none;
    box-shadow: 0px 20px 20px -18px rgba(0, 0, 0, 0.1);
  }

  &.btn-responsive {
    @include media-breakpoint-up(xl) {
      padding: 0 get-vw-desktop(12px);
      height: get-vw-desktop(38px);
      font-size: get-vw-desktop(18px);
      border-radius: get-vw-desktop(38px);

      .icon {
        margin-right: 0.833vw;
        font-size: 1.389vw;
        width: 1.389vw;
        height: 1.389vw;
        color: inherit;
      }
    }
  }

  //////////////////////////////////////////
  // Size variants
  //////////////////////////////////////////
  &.btn-small {
    padding: 0 10px;
    height: 35px;
    font-size: 12px;
    &.btn-responsive {
      @include media-breakpoint-up(xl) {
        height: 2.083vw;
        font-size: 0.972vw;
        padding: 0 1.042vw;
      }
    }
  }

  &.btn-large {
    padding: 0 15px;
    height: 45px;
    font-size: 18px;

    &.btn-responsive {
      @include media-breakpoint-up(xl) {
        padding: 0 1.319vw;
        height: 2.639vw;
        font-size: 1.181vw;
      }
    }
  }

  &.btn-extra-large {
    padding: 0 15px;
    height: 54px;
    font-size: 18px;

    &.btn-responsive {
      @include media-breakpoint-up(xl) {
        padding: 0 1.319vw;
        height: 3.75vw;
        font-size: 1.181vw;
      }
    }
  }

  //////////////////////////////////////////
  // Rounded variant
  //////////////////////////////////////////
  &.btn-rounded {
    padding: 0 20px;
    border-radius: 20px;

    &.btn-responsive {
      @include media-breakpoint-up(xl) {
        padding: 0 1.215vw;
        border-radius: 1.215vw;
      }
    }

    &.btn-small {
      padding: 0 17.5px;
      border-radius: 17.5px;
      &.btn-responsive {
        @include media-breakpoint-up(xl) {
          padding: 0 1.042vw;
          border-radius: 1.042vw;
        }
      }
    }

    &.btn-large {
      padding: 0 22.5px;
      border-radius: 22.5px;
      &.btn-responsive {
        @include media-breakpoint-up(xl) {
          padding: 0 1.319vw;
          border-radius: 1.319vw;
        }
      }
    }

    &.btn-extra-large {
      padding: 0 27px;
      border-radius: 27px;
      &.btn-responsive {
        @include media-breakpoint-up(xl) {
          padding: 0 1.875vw;
          border-radius: 1.875vw;
        }
      }
    }
  }

  //////////////////////////////////////////
  // Color variants
  //////////////////////////////////////////
  &.btn-primary {
    color: $btn-color-primary;
    background-color: $btn-bg-color-primary;
  }

  &.btn-primary-gradient {
    color: $btn-color-primary;
    //background: linear-gradient(90deg, #01BDFA 0%, #01FBFF 100%);
    background-color: $btn-bg-color-primary;
  }

  &.btn-primary-outline {
    color: $btn-bg-color-primary;
    border: 3px solid $btn-bg-color-primary;
    background-color: transparent;
  }

  &.btn-secondary {
    color: $btn-color-secondary;
    background-color: $btn-bg-color-secondary;
  }

  &.btn-dark {
    color: #fff;
    background-color: #200e32;
    border: 1px solid #ffffff;
  }

  &.btn-light {
    color: #fff;
    background-color: #b0b5bf;
    border: 1px solid #ffffff;
  }

  &.btn-dark-blue {
    color: #fff;
    background-color: #200e32;
  }

  &.btn-red {
    color: #fff;
    background-color: #eb5757;
  }

  &.btn-gray {
    color: #000;
    background-color: #f6f7fb;
  }

  &.btn-green {
    color: #fff;
    background-color: #3bd984;
  }

  &.btn-black {
    color: #fff;
    background-color: #000;
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: #b0b5bf;
  }

  //////////////////////////////////////////
  // Icons
  //////////////////////////////////////////

  &:not(.back-button) {
    .icon {
      margin-right: 12px;
      font-size: 26px;
      width: 26px;
      height: 26px;
      color: inherit;
      .rtl & {
        margin-right: unset;
        margin-left: 12px;
      }
    }
  }
}

//////////////////////////////////////////
// Address button
//////////////////////////////////////////
.btn-address {
  position: relative;
  margin-bottom: 10px;
  padding: 16px;
  width: 100%;
  text-align: left;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  background-color: #fff;
  border-radius: 15px;

  .rtl & {
    padding: 16px;
    text-align: right;
  }

  .chevron-icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translate(0, -50%);
    z-index: 1;

    .rtl & {
      right: unset;
      left: 16px;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    button {
      margin-right: 10px;

      .rtl & {
        margin-right: unset;
        margin-left: 10px;
      }
    }
  }
}

.btn-group-toggle {
  > .btn,
  > .btn-group > .btn {
    border-radius: 0;
    outline: 1px solid #f0f1f5;
    text-transform: none;

    &:first-child {
      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
    }

    &.btn-extra-large {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      padding: 0 27px;

      &.active {
        font-weight: 700;
      }
    }

    &.btn-gray {
      background-color: #f0f1f5;
      color: #777b84;

      &.active {
        background-color: #fff;
        color: #161616;
      }
    }
  }
}
