.modal.order-confirmation-modal {
  .modal-dialog {
    margin: 0 10px;
    width: auto;
    @include media-breakpoint-up(md) {
      margin: auto;
    }
    .modal-content {
      position: relative;
      padding: 32px 26px;
      border-radius: 15px;
      background-color: #fff;

      app-order-confirmation-modal {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .modal-title {
          margin-top: 70px;
          margin-bottom: 26px;
          font-size: 24px;
          font-weight: 700;
          @include media-breakpoint-up(xl) {
            margin-bottom: 32px;
          }
        }

        p {
          margin-bottom: 0;
          font-weight: 500;
          font-size: 16px;
          line-height: 140%;
          text-align: center;
          color: #58575a;
          text-align: center;
        }

        .order-details {
          font-size: 17px;
          line-height: 28px;
          text-align: center;

          .description {
            color: $headings-color;
          }
        }

        .seperator {
          margin: 32px 0;
          width: 100%;
          border-bottom: 1px solid #9aa4ad4d;
          @include media-breakpoint-up(xl) {
            width: 70%;
          }
        }

        .btn-back {
          min-width: 100%;
          @include media-breakpoint-up(xl) {
            min-width: 70%;
          }
        }
      }
    }
  }
}
