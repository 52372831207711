.pause-broadcast-modal {
  .modal-dialog {
    margin: 0 10px;
    width: auto;
    @include media-breakpoint-up(md) {
      margin: auto;
    }

    .btn-close-modal-mobile {
      // TODO: clean up after restyling is done
      width: 27px !important;
      height: 27px !important;
      opacity: 0.5;
      left: unset !important;
      right: 20px !important;
      .rtl & {
        right: unset !important;
        left: 20px !important;
      }
    }
    .modal-content {
      position: relative;
      padding-top: 80px;
      border-radius: 15px;
      background-color: #fff;

      app-pause-broadcast-modal {
        .buttons-container {
          display: flex;
          flex-direction: column;
          margin: auto;
          width: 236px;
          .btn-base {
            margin-bottom: 20px;
            svg {
              margin-right: 10px;
            }
          }
        }
        .end-session-confirmation-modal {
          position: absolute;
          left: 44%;
          transform: translate(-50%, -40%);
        }
      }
    }
  }
}
