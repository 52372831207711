.session-review-modal {
  .modal-dialog {
    margin: 0 10px;
    width: auto;
    @include media-breakpoint-up(md) {
      margin: auto;
      width: 678px;
    }

    .modal-content {
      position: relative;
      border-radius: 15px;
      background-color: #fff;

      app-session-review-modal {
        .checkmark-circle {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100px;
          height: 100px;
          z-index: 1;
          user-select: none;
          @include media-breakpoint-up(xl) {
            transform: translate(-50%, calc(-50% - 20px));
          }
        }

        .title {
          margin-top: 54px;
          margin-bottom: 25px;
          text-align: center;
          font-weight: 700;
          font-size: 30px;
          line-height: 1.3;
          letter-spacing: -0.03em;
          color: #3bd984;
          @include media-breakpoint-up(xl) {
            margin-bottom: 35px;
            font-size: 44px;
          }
        }

        .sub-title {
          margin-bottom: 25px;
          font-size: 30px;
          line-height: 1.3;
          text-align: center;
          letter-spacing: -0.03em;
          color: #000000;

          @include media-breakpoint-up(xl) {
            margin-bottom: 35px;
            font-size: 30px;
          }
        }

        //////////////////////////////////////////
        // Session feedback
        //////////////////////////////////////////
        .session-feedback {
          margin: 0 auto 30px;

          .feedback-reactions {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 15px;
            margin: 0 -10px 15px 0;

            @include media-breakpoint-up(xl) {
              margin: 0 0 25px;
            }

            .rtl & {
              margin: 0 0 15px -10px;
            }

            .btn-reaction {
              margin-right: 10px;
              margin-bottom: 10px;
              padding-left: 50px;
              flex: 0 0 152px;
              color: #fff;
              font-size: 15px;
              background-repeat: no-repeat;
              background-position: 15px center;

              &.boring {
                background-color: #403871;
                background-image: url('/assets/images/icons/boring-smiley-icon.svg');
                background-size: 35px auto;
              }
              &.frustrating {
                background-color: #f96544;
                background-image: url('/assets/images/icons/frustrating-smiley-icon.svg');
                background-size: 24px auto;
              }
              &.terrific {
                background-color: #31d0aa;
                background-image: url('/assets/images/icons/terrific-smiley-icon.svg');
                background-size: 24px auto;
              }
              &.fine {
                background-color: #ef60ab;
                background-image: url('/assets/images/icons/fine-smiley-icon.svg');
                background-size: 25px auto;
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }

          .feedback-message {
            padding: 8px 14px;
            width: 100%;
            font-size: 15px;
            line-height: 21px;
            border: 0;
            outline: 0;
            border-radius: 10px;
            background-color: #f3f3f3;
          }
        }

        .btn-send {
          display: flex;
          margin: 0 auto;
          width: 228px;
          @include media-breakpoint-up(xl) {
            width: 288px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
