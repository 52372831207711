.wow-pop-up {
  background: rgba(138, 138, 138, 0.7);
  backdrop-filter: blur(3px);
  .modal-content {
    border-radius: 12px;
    background: rgba(230, 230, 230, 0.6);
    backdrop-filter: blur(8px);
    min-height: 210px;
  }
}
