.payment-details-modal {
  .modal-dialog {
    @include media-breakpoint-down(sm) {
      margin: 10px;
      width: auto;
    }
    .modal-content {
      app-payment-details-modal {
        ngx-stripe-card-group {
          display: block;
          margin-top: 20px;
        }

        .custom-input {
          margin-bottom: 10px;
        }

        .input-wrapper {
          display: inline-table;
          margin-bottom: 10px;
          width: 100%;
          height: 50px;
          padding: 16px;
          border-radius: 10px;
          border: 0;
          background-color: #f6f7fb;
          &.half {
            width: calc(50% - 5px);
            &.with-margin {
              margin-left: 10px;
              .rtl & {
                margin-left: unset;
                margin-right: 10px;
              }
            }
          }
        }

        .btn-submit {
          width: 100%;
          &.btn-save-card {
            margin-top: 20px;
          }
        }

        //////////////////////////////////////////
        // Payment options buttons
        //////////////////////////////////////////
        .payment-options {
          .btn-payment {
            position: relative;
            margin-bottom: 10px;
            padding-left: 100px;
            width: 100%;
            height: 60px;
            text-align: left;
            color: #fff;
            font-weight: 500;
            font-size: 14px;
            background-color: #000;
            background-image: url('/assets/images/payment-button-bg.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: left top;
            border-radius: 15px;

            .rtl & {
              padding-left: unset;
              padding-right: 100px;
              text-align: right;
              background-position: right top;
              background-image: url('/assets/images/payment-button-bg-rtl.svg');
            }

            .chevron-icon {
              position: absolute;
              top: 24px;
              right: 16px;
              .rtl & {
                right: unset;
                left: 16px;
                transform: scaleX(-1);
              }
            }
            .company-icon {
              position: absolute;
              top: 12px;
              left: 16px;
              width: 58px;
              height: 36px;
              object-fit: contain;
              object-position: center;
              .rtl & {
                left: unset;
                right: 16px;
              }
            }

            &[aria-expanded='true'] {
              .chevron-icon {
                transform: rotate(90deg);
              }
            }
          }
          .btn-submit {
            margin-top: 20px;
            width: 100%;
          }

          .btn-delete {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}
