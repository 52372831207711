ng-scrollbar.custom-scrollbar {
  --scrollbar-border-radius: 5px;
  --scrollbar-padding: 2px;
  --scrollbar-viewport-margin: -13px;
  --scrollbar-track-color: transparent;
  --scrollbar-wrapper-color: transparent;
  --scrollbar-thumb-color: #b0b5bf;
  --scrollbar-thumb-hover-color: var(--scrollbar-thumb-color);
  --scrollbar-size: 9px;
  --scrollbar-hover-size: var(--scrollbar-size);
  --scrollbar-thumb-transition: height ease-out 150ms, width ease-out 150ms;
  --scrollbar-track-transition: height ease-out 150ms, width ease-out 150ms;
  --scrollbar-overscroll-behavior: contain;
  .ng-scrollbar-wrapper {
    > .ng-scroll-viewport-wrapper {
      > .ng-scroll-viewport {
        --native-scrollbar-size: 0 !important;
      }
    }
    > scrollbar-y.scrollbar-control {
      > .ng-scrollbar-track {
        overflow: unset;
        width: 1px;
        background-color: #b0b5bf;
        @include media-breakpoint-down(xl) {
          right: -13px;
        }
        .rtl & {
          @include media-breakpoint-down(xl) {
            right: unset;
            left: -13px;
          }
        }
        .ng-scrollbar-thumb {
          margin: 0 -4px;
          width: 9px;
        }
      }
    }
    > scrollbar-x.scrollbar-control {
      > .ng-scrollbar-track {
        overflow: unset;
        width: 1px;
        background-color: #b0b5bf;
        @include media-breakpoint-down(xl) {
          top: -13px;
        }
        .ng-scrollbar-thumb {
          margin: 0 -4px;
          width: 9px;
        }
      }
    }
  }
}
