:root {
  --vh: 1vh;
  @supports (height: 100dvh) {
    --vh: 1dvh;
  }
  /* typography */
  --white-typography-Dark: #161616;
  --white-typography-Dark2: #777b84;
}

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
  xxl: 1650px,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px,
  xxl: 1380px,
) !default;

$grid-gutter-width: 10px;
$enable-caret: false;
//////////////////////////////////////////
// Colors
//////////////////////////////////////////
$body-color: #000;
$primary: #f39449;
$secondary: #b0b5bf;
$disabled: #b0b5bf;
$dangher: #eb5757;
$light: #fff;
$brand-pink: #ff53a1;

$headings-color: #403871;

$navbar-light-color: #050e2e;

$text-muted: #9e9ea9;

$inputApproved: #21d3c9;

//////////////////////////////////////////
// Font
//////////////////////////////////////////
$font-family-sans-serif: 'Heebo', 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

//////////////////////////////////////////
// Tooltip
//////////////////////////////////////////
$tooltip-bg: #fff;
$tooltip-opacity: 1;

//////////////////////////////////////////
// Forms
//////////////////////////////////////////
$form-group-margin-bottom: 10px;

//////////////////////////////////////////
// Custom controls
//////////////////////////////////////////
$custom-control-indicator-size: 1.5rem;

//////////////////////////////////////////
// Dropdown
//////////////////////////////////////////
$dropdown-padding-y: 0;
//////////////////////////////////////////
// Popover
//////////////////////////////////////////
$popover-bg: #eaebef;
$popover-max-width: auto;
$popover-border-width: 0;
$popover-border-color: transparent;
$popover-border-radius: 20px;
$popover-inner-border-radius: subtract($popover-border-radius, $popover-border-width);
$popover-box-shadow: 0px 10px 32px -18px #000000;

$popover-body-color: $body-color;
$popover-body-padding-y: 25px;
$popover-body-padding-x: 25px;

$popover-arrow-width: 1rem;
$popover-arrow-height: 0.5rem;
$popover-arrow-color: $popover-bg;

$popover-arrow-outer-color: fade-in($popover-border-color, 0.05);

$btn-color-primary: var(--light-store, $light);
$btn-bg-color-primary: var(--primary-store, $primary);

$btn-color-secondary: var(--primary-store, $primary);
$btn-bg-color-secondary: var(--light-store, $light);
