.currency-select-modal {
  .modal-dialog {
    margin: 0 10px;
    width: auto;
    @include media-breakpoint-up(md) {
      margin: auto;
    }
    .modal-content {
      position: relative;
      border-radius: 15px;
      background-color: #fff;

      app-currency-select-modal {
        .search-currency {
          padding-left: 44px;
          background-color: rgba(#dfdede, 0.3);
          border-radius: 10px;
          background-image: url('/assets/images/icons/search-icon.svg');
          background-repeat: no-repeat;
          background-position: 10px center;

          .rtl & {
            padding-left: unset;
            padding-right: 44px;
            background-position: calc(100% - 10px) center;
          }
        }

        .currency-list-container {
          margin: 20px 0 40px;
          width: 100%;
          .currency-items {
            max-height: 360px;

            .currency-item {
              display: flex;
              align-items: center;
              padding-left: 32px;
              width: 100%;
              height: 67px;
              border-bottom: 1px solid #f3f3f3;

              .rtl & {
                padding-left: unset;
                padding-right: 32px;
              }

              .checkbox {
                margin-right: 48px;
                width: 32px;
                height: 32px;
                background-color: #f8f8f8;
                box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1);
                border-radius: 20px;
                .rtl & {
                  margin-right: unset;
                  margin-left: 48px;
                }
              }

              &.active {
                .checkbox {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #31d0aa;
                  &::after {
                    display: block;
                    content: '';
                    width: 16px;
                    height: 16px;
                    background-color: #fff;
                    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 2px 10px rgba(14, 14, 44, 0.15);
                    border-radius: 50%;
                  }
                }
              }

              i.flag {
                margin-right: 38px;
                .rtl & {
                  margin-right: unset;
                  margin-left: 38px;
                }
              }
            }
          }
        }

        .btn-done {
          min-width: 180px;
        }
      }
    }
  }
}
