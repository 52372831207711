.user-dropdown {
  position: absolute;
  right: 7.5px;
  left: auto;
  top: 70px;
  padding: 30px;
  width: 360px;
  min-width: auto;
  background: #f6f7fb;
  box-shadow: 0px 20px 50px -18px rgba(0, 0, 0, 0.8);
  border-radius: 20px;
  border: 0;

  @include media-breakpoint-up(xl) {
    top: 88px;
  }

  .rtl & {
    right: auto;
    left: 7.5px !important;
  }

  .user-details {
    display: flex;
    padding: 10px;
    background: rgba(#99a1a8, 0.3);
    border-radius: 20px;

    .avatar {
      width: 45px;
      height: 45px;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-left: 14px;

      .rtl & {
        margin-left: unset;
        margin-right: 14px;
      }

      .btn-edit {
        padding: 0;
        font-size: 14px;
        line-height: 1;
        color: #5e6978;
        text-align: left;
        &:hover {
          text-decoration: underline;
          background-color: unset;
        }

        .rtl & {
          text-align: right;
        }
      }
      .user-name {
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.03em;
      }
    }
  }

  .dropdown-items {
    padding: 22px 0 0;
    .current-version {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      margin-left: 14px;
      color: #000000;
    }
    .dropdown-item {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 10px 18px;
      font-weight: 500;
      font-size: 20px;
      letter-spacing: -0.03em;
      text-transform: capitalize;
      color: #28374b;
      text-decoration: none;
      border-radius: 10px;

      &:last-child {
        margin-top: 22px;
      }

      img {
        margin-right: 28px;
        width: 24px;
        height: 24px;
        object-fit: contain;
        object-position: center;

        .rtl & {
          margin-right: unset;
          margin-left: 28px;
        }
      }

      &:hover {
        background-color: #b0b5bf;
      }
    }

    .btn-logout {
      width: 100%;
      height: 54px;
      font-weight: 600;
      color: var(--light-store);
      text-transform: uppercase;
      justify-content: center;
      background-color: var(--primary-store);
      border-radius: 54px;

      img {
        margin-right: 22px;
        .rtl & {
          margin-right: unset;
          margin-left: 22px;
        }
      }
    }
  }
}
