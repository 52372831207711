//////////////////////////////////////////
// Tabs
//////////////////////////////////////////
.tabs-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 20%;
  padding: 18px 24px 18px 0;
  .rtl & {
    padding: 18px 0 18px 24px;
  }
  .nav {
    // width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    .nav-item {
      flex-grow: 1;
      height: 100%;
      list-style-type: none;
      .nav-link {
        position: relative;
        padding: 9px 18px 0 9px;
        height: 100%;
        color: #58575a;
        font-weight: 500;
        font-size: get-vw-desktop(20px);
        line-height: get-vw-desktop(24px);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #c3cbdc;
        text-align: center;
        &.messages-tab-link {
          @media screen and (max-width: 1650px) {
            padding-right: 50px;
          }
          .rtl & {
            @media screen and (max-width: 1650px) {
              padding-right: unset;
              padding-left: 50px;
            }
          }
        }
        &.active {
          color: #58575a;
          border-bottom: 4px solid var(--secondary-store);
        }
        small {
          font-size: 80%;
          font-weight: 500;
          line-height: 1.1;
        }
        i {
          margin-right: 15px;
          font-size: 24px;
          @include media-breakpoint-down(xl) {
            display: none;
          }
          .rtl & {
            margin-right: unset;
            margin-left: 15px;
          }
        }
        .badge {
          position: absolute;
          right: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 55px;
          height: 30px;
          color: #fff;
          background: linear-gradient(180deg, #cc8fff 0%, #e98fff 100%);
          border-radius: 15px;
          .rtl & {
            right: unset;
            left: 45px;
          }
          img {
            margin-right: 10px;
            .rtl & {
              margin-right: unset;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
  .tab-content {
    display: contents;
    flex-grow: 1;
    .tab-pane {
      height: 100%;
      .tab-inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        //////////////////////////////////////////
        // Product
        //////////////////////////////////////////
        .product-wrapper {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          margin-top: 2.5vw;
          width: 98%;
          .title {
            margin-bottom: get-vw-desktop(8px);
            color: #58575a;
            font-size: get-vw-desktop(14px);
            line-height: 134%;
            font-weight: 600;
          }
          .product-image-wrapper {
            position: relative;
            width: get-vw-desktop(285px);
            height: get-vw-desktop(275px);
            .product-image {
              max-height: 42vh;
              width: 100%;
              height: 100%;
              border: 0.347vw solid #fff;
              border-radius: 0.694vw;
              object-fit: contain;
              object-position: center;
              background-color: #fff;
            }
            .sold-out-badge {
              position: absolute;
              top: 0;
              margin: 4px;
              padding: 0.208vw 0.417vw;
              z-index: 3;
              display: flex;
              align-items: center;
              color: #ffffff;
              font-size: 0.556vw;
              font-weight: 700;
              line-height: 120%;
              letter-spacing: 0.04em;
              text-transform: uppercase;
              border-radius: 1.042vw;
              background: #f96544;
              .rtl & {
                right: unset;
                left: 1.042vw;
              }
            }
          }
          .item-page-details {
            max-width: 46%;
          }
          .product-details {
            flex: 1 1 auto;
            margin-top: 16px;
            margin-left: 20px;
            overflow: hidden;
            .rtl & {
              padding-left: unset;
            }
            .description-counter {
              font-size: 0.8rem;
              .description {
                font-size: 0.9rem;
                color: black;
              }
            }
            .title {
              margin-bottom: 0.347vw;
              color: $headings-color;
              font-size: 1.111vw;
              font-weight: 600;
              line-height: 120%;
            }
            .price {
              display: flex;
              align-items: center;
              color: #000;
              font-size: 1.111vw;
              font-weight: 600;
              flex-wrap: wrap;
              column-gap: 0.556vw;
              .previous-price {
                color: #b0b5bf;
                font-weight: 400;
                font-size: 80%;
                text-decoration: line-through;
              }
            }
            .arrangementVariants {
              display: flex;
              flex-wrap: wrap;
              gap: 4px;
            }

            #variant-img {
              margin-right: 0vw;
            }
            .model-variants {
              margin-bottom: 1.042vw;
              .model-variant {
                width: 4.167vw;
                height: 4.167vw;
                border-radius: 0.347vw;
                margin-right: 0.278vw;
                .rtl & {
                  margin-right: unset;
                  margin-left: 0.278vw;
                }
                .model-image {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                  object-position: center;
                  background-color: #fff;
                }
              }
            }
            .variants {
              display: flex;
              margin-bottom: 1.042vw;
              color: #000;
              font-size: 0.903vw;
              font-weight: 300;
              flex-direction: column;
              gap: 8px;
              span {
                flex: 0 0 3.611vw;
              }
              .dropdown-toggle {
                min-width: 8vw;
              }
            }
          }
          .product-customization {
            display: flex;
            flex: 0 0 100%;
            margin-top: get-vw-desktop(20px);
            .customization-text {
              flex: 0 0 get-vw-desktop(285px);
              &.customization-text-image {
                flex: 0 0 get-vw-desktop(235px);
              }
              .live-session-host & {
                flex: 0 0 50%;
              }
              p {
                font-size: get-vw-desktop(11.35px);
              }
            }
            .customization-form {
              flex: 1 1 auto;
              margin-right: get-vw-desktop(16px);
              padding-left: get-vw-desktop(20px);
              .rtl & {
                margin-right: unset;
                margin-left: get-vw-desktop(16px);
                padding-left: unset;
                padding-right: get-vw-desktop(20px);
              }
              &.customization-form-image {
                .hint-text {
                  margin-left: get-vw-desktop(16px);
                  .rtl & {
                    margin-left: unset;
                    margin-right: get-vw-desktop(16px);
                  }
                }
                .btn-remove {
                  margin-left: get-vw-desktop(16px);
                  .rtl & {
                    margin-left: unset;
                    margin-right: get-vw-desktop(16px);
                  }
                  svg {
                    width: get-vw-desktop(12px);
                    height: get-vw-desktop(12px);
                  }
                }
              }
              label {
                display: block;
                font-size: get-vw-desktop(14px);
              }
              .customization-label-input {
                height: get-vw-desktop(35px);
                font-size: get-vw-desktop(14px);
                font-weight: 500;
                background-color: #fff;
                &.upto-10 {
                  width: get-vw-desktop(105px);
                }
                &.upto-20 {
                  width: get-vw-desktop(240px);
                }
              }
              .hint-text {
                font-size: get-vw-desktop(10px);
              }
            }
          }
          .product-description {
            margin-top: 1.944vw;
            padding-right: 1.042vw;
            flex: 0 0 20.278vw;
            font-size: get-vw-desktop(11.35px);
            .rtl & {
              padding-right: unset;
              padding-left: 1.042vw;
            }
            .read-more {
              display: flex;
              align-items: center;
              margin-top: 0.417vw;
              font-size: get-vw-desktop(11.35px);
              color: #c1c1c1;
              img {
                width: 0.833vw;
              }
              &[aria-expanded='true'] {
                img {
                  transform: rotate(180deg);
                }
              }
            }
          }
          .extra-info {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            margin-top: 1.944vw;
            padding-left: 1.042vw;
            height: 4.167vw;
            // border-left: 1px solid #000;
            .rtl & {
              padding-left: unset;
              padding-right: 1.042vw;
              border-left: unset;
              // border-right: 1px solid #000;
            }
            a,
            button {
              text-align: left;
              color: #000000;
              font-size: 0.833vw;
              line-height: 142%;
              .rtl & {
                text-align: right;
              }
              img {
                margin-right: 0.694vw;
                width: 1.042vw;
                height: 1.042vw;
                .rtl & {
                  margin-right: unset;
                  margin-left: 0.694vw;
                }
              }
            }
          }
          .loader-on-live-session-user {
            margin-right: 214px;
          }
          .buttons {
            margin-top: 0.694vw;
            width: 100%;
            display: flex;

            button {
              margin-right: 1.111vw;
              .rtl & {
                margin-right: unset;
                margin-left: 1.111vw;
              }
            }
          }
        }
        .closeFocusedProduct {
          width: 30px;
          height: 30px;
          margin-top: 2.5vw;
          margin-right: 2.5vw;
        }
        //////////////////////////////////////////
        // Products
        //////////////////////////////////////////
        .products-wrapper {
          margin-top: 1.111vw;
        }
        //////////////////////////////////////////
        // Analytics
        //////////////////////////////////////////
        .stats-container {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-end;
          margin-top: 2.5vw;
          width: 100%;
          .stat-item {
            flex: 0 1 calc(100% / 3);
            margin-bottom: 2.083vw;
            padding-right: 1.389vw;
            font-weight: 700;
            font-size: 1.389vw;
            line-height: 1.5;
            .rtl & {
              padding-right: unset;
              padding-left: 1.389vw;
            }
            .stat-value {
              color: var(--primary-store);
            }
          }
        }
        .product-details-scrollbar {
          .closeFocusedProduct {
            position: absolute;
            right: 5px;
            top: 12px;
            margin: 0;
          }
          .products-wrapper {
            .products {
              padding-top: 0.833vw;
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(8.75vw, 1fr));
            }
          }
        }
        .products-wrapper {
          .products {
            padding-top: 0.833vw;
          }
        }
      }
    }
  }
  .tabs-title {
    position: relative;
    padding: 9px 18px 0 9px;
    height: 100%;
    color: #58575a;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.6666666667vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
  }
}

//////////////////////////////////////////
// Tabs style 2 (black bottom border)
//////////////////////////////////////////
.nav.nav-style-2 {
  width: 100%;
  .nav-item {
    height: 100%;
    margin-inline-end: get-vw-desktop(24px);
    .nav-link {
      position: relative;
      padding: get-vw-desktop(4px) 0;
      height: 100%;
      color: #bdbdbd;
      font-size: get-vw-desktop(16px);
      line-height: get-vw-desktop(20px);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 3px solid #0000;
      &.active {
        color: #58575a;
        padding: 9px 0 6px px;
        border-bottom: 4px solid var(--secondary-store);
      }
    }
  }
}

.analytics-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .ng-scroll-content {
    display: flex;
    flex-direction: column;
  }
}
.app-card {
  width: 296px;
  height: 213px;
}
.analytics-cube {
  position: relative;
  height: auto;
  width: 100%;
  overflow: hidden;
  margin-bottom: 3vh;
  &.analytics-cube-mobile {
    width: auto;
  }
  .sidebar {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.swiper-erea {
  width: 100%;
  height: auto;
}
.swiper-slide {
  width: auto;
}
.analytics-info-header {
  font-weight: 600;
  font-size: 16px;
  line-height: 132%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #000000;
  margin-top: 12px;
}
.swiper-Last,
.swiper-upcoming,
.swiper-polls {
  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after,
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    position: relative;
    min-width: 42px;
    height: 42px;
    transform: scale(0.9);
    background: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 60px;
    font-size: 14px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15))
      drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05));
    transition: all 0.5s;
  }
  .swiper-button-prev:hover:after,
  .swiper-rtl .swiper-button-next:hover:after,
  .swiper-button-next:hover:after,
  .swiper-rtl .swiper-button-prev:hover:after {
    transform: scale(1.05);
  }
}
.live {
  .video-mobile {
    transform: scale(0.8);
    transition: all 0.5s;
    @include media-breakpoint-down(sm) {
      transform: scale(0.95);
    }
  }
  .swiper-slide-active .video-mobile {
    transform: scale(0.9);
    @include media-breakpoint-down(sm) {
      transform: scale(1.05);
    }
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25), 0px 20px 46px -18px rgba(0, 0, 0, 0.23);
  }
  .btns-on-video,
  .btn-mute {
    transition: all 0.5s;
    opacity: 1;
  }
  .swiper-slide:not(.swiper-slide-active) .btns-on-video,
  .swiper-slide:not(.swiper-slide-active) .btn-mute {
    opacity: 0;
    pointer-events: none;
  }
  .swiper-items {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 100%);
  }
  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after,
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    min-width: 42px;
    height: 42px;
    transform: scale(0.9);
    background: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 60px;
    font-size: 14px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15))
      drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05));
    transition: all 0.5s;
  }
  .swiper-button-prev:hover:after,
  .swiper-rtl .swiper-button-next:hover:after,
  .swiper-button-next:hover:after,
  .swiper-rtl .swiper-button-prev:hover:after {
    transform: scale(1.05);
  }
  .btn-base.btn-rounded.btn-large {
    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 25px;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 25px;
  }
}
.focusedProduct {
  display: flex;
  position: relative;
}

.swiper-slide {
  #active-text {
    display: none;
  }
  #inactive-text {
    display: block;
  }
}
.swiper-slide-active {
  #active-text {
    display: block;
  }
  #inactive-text {
    display: none;
  }
}
.add-to-cart-icon {
  margin-right: 0.5rem;
}
