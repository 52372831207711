//////////////////////////////////////////
// Products
//////////////////////////////////////////
.session-screen-desktop {
  .products-wrapper {
    flex-grow: 1;
    .products {
      display: flex;
      margin: 0 -0.347vw 0 -0.347vw;
      flex-wrap: wrap;
      width: 100%;

      .product-item {
        display: flex;
        flex-direction: column;
        position: relative;
        margin: 0 0.347vw 0.694vw;
        padding: 0.417vw;
        flex: 0 0 calc(25% - 0.694vw);
        border-radius: 0.625vw;
        border: 1px solid #dde2eb;
        transition: all 300ms ease-out;
        background-color: #fff;
        cursor: pointer;

        &.selected {
          border-color: var(--secondary-store);
        }

        &.unavailable {
          cursor: default;
          .item-picture {
            position: relative;
            &::after {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              content: '';
              background-image: url('/assets/images/lock.svg');
              background-position: center;
              background-repeat: no-repeat;
              pointer-events: none;
              background-size: 18%;
              z-index: 1;
            }

            .product-image {
              object-fit: contain;
            }
          }
          .views-needed {
            flex-grow: 1;
            font-size: 0.903vw;
            text-align: center;

            i {
              margin-right: 5px;
              font-size: 0.694vw;

              .rtl & {
                margin-right: unset;
                margin-left: 5px;
              }
            }
          }
        }

        .item-picture {
          position: relative;
          margin: 0 auto 0.417vw;
          width: 100%;
          height: 8.333vw;
          border-radius: 0.417vw;
          overflow: hidden;

          .product-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }

          .brand-logo {
            position: absolute;
            top: 0.347vw;
            left: 0.347vw;
            bottom: 0;
            width: 1.944vw;
            height: 1.944vw;
            object-fit: contain;
            object-position: center;
            z-index: 2;
          }

          .sold-out-badge {
            position: absolute;
            top: 0;
            margin: 4px;
            padding: 0.208vw 0.417vw;
            z-index: 3;
            display: flex;
            align-items: center;
            color: #ffffff;
            font-size: 0.556vw;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            border-radius: 1.042vw;
            background: #f96544;

            .rtl & {
              right: unset;
              left: 0.694vw;
            }
          }
        }

        .item-title {
          margin-bottom: 0.417vw;
          font-size: 0.903vw;
          line-height: 1.083vw;
          @extend .line-clamp-2;
        }
        .prices-addition {
          display: flex;
          justify-content: space-between;
          z-index: 999;
        }
        .item-price {
          margin-top: auto;
          margin-bottom: 0;
          color: var(--secondary-store);
          font-style: normal;
          font-weight: 600;
          font-size: 1.042vw;

          .previous-price {
            color: #5e6978;
            font-weight: normal;
            text-decoration: line-through;
          }
        }
        .quick-add-to-card {
          position: absolute;
          padding: 6.5px;
          width: 34px;
          right: 5px;
          bottom: 5px;
          height: 34px;
          background: var(--primary-store);
          border: 1px solid #ffffff;
          box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.11);
          border-radius: 60px;
        }

        .items-sold {
          position: absolute;
          top: -0.6vw;
          right: -0.6vw;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2.5vw;
          height: 2.5vw;
          color: #fff;
          font-weight: 600;
          font-size: 1.25vw;
          letter-spacing: -0.03em;
          background-color: #ff647c;
          border-radius: 50%;
          .rtl & {
            right: auto;
            left: -0.6vw;
          }
        }
      }
    }
  }
}
