.store-adding-session {
  margin-top: -30px;
  padding-bottom: 120px;
  .create-session-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .headline {
      h3 {
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
  .bg-block {
    border-radius: 10px;
    background-color: #c4c4c410;
  }
  .bg-block-2 {
    border-radius: 10px;
    background-color: #f6f7f8;
  }
  h2 {
    font-weight: 600;
    font-size: 34px;
    margin-bottom: 20px;
  }
  h3 {
    font-size: 25px;
    font-weight: 900;
    margin: 30px 0 15px;
  }
  .session-details {
    display: flex;
    .session-description {
      flex: 0.37;
      .form-row {
        margin: 0;
      }
      .file {
        display: none;
      }
      .btn-base {
        padding: 0 15px;
        border-radius: 5px;
        margin-left: 10px;
        font-size: 13px;
        font-weight: 500;
        .rtl & {
          margin-left: unset;
          margin-right: 10px;
        }
      }
      label[for='start-time'],
      #start-time {
        margin-left: 10px;
        .rtl & {
          margin-left: unset;
          margin-right: 10px;
        }
      }
      .product-input {
        padding: 0 6px 0 14px;
        .rtl & {
          padding: 0 14px 0 6px;
        }
      }
      .product-textarea {
        resize: none;
      }
      .datetime {
        justify-content: space-between;
        .form-group {
          .form-control {
            border-right: none;
            background-color: transparent;
            .rtl & {
              border-right: 1px solid #ccc;
              border-left: none;
            }
          }
          .input-group-append {
            .rtl & {
              margin-left: 0;
              margin-right: -1px;
            }
            button {
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #ccc;
              border-radius: 0 0.25rem 0.25rem 0;
              border-left: 1px solid transparent;
              background-color: transparent;
              .rtl & {
                border-left: 1px solid #ccc;
                border-right: 1px solid transparent;
                border-radius: 0.25rem 0 0 0.25rem;
              }
            }
          }
          &:nth-child(1) {
            flex: 0.6;
            max-width: 180px;
          }
          &:nth-child(2) {
            flex: 0.4;
          }
        }
      }
      #description {
        height: 150px;
        padding-top: 10px;
      }
    }

    .session-preview {
      flex: 0.38;
      margin: 20px 0 0 30px;
      width: 100%;
      padding: 25px;
      display: flex;
      flex-direction: column;
      max-height: 706px;
      .rtl & {
        margin: 20px 30px 0 0;
      }
      * {
        pointer-events: none;
        user-select: none;
      }
      .datetime {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 15px;
        span {
          margin: 0 5px;
        }
      }
      .speaker-img {
        margin-bottom: 20px;
        width: 175px;
        height: 210px;
        border-radius: 15px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center top;
        }
      }
      .collaboration {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 20px;
        .brand {
          display: flex;
          align-items: center;
          flex: 1 1 50%;
          .brand-logo {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            flex: 0 0 52px;
            height: 52px;
            border-radius: 50%;
            background-color: #fff;
            overflow: hidden;
            .rtl & {
              margin-right: unset;
              margin-left: 10px;
            }
            img {
              width: calc(100% - 10px);
              height: calc(100% - 10px);
              object-fit: contain;
              object-position: center;
            }
          }
          span {
            font-weight: 600;
            font-size: 12px;
            text-align: center;
            line-height: 120%;
            @extend .line-clamp-2;
          }
        }
        .and {
          margin: 0 29px;
          font-weight: 200;
          font-size: 36px;
        }
        .host {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          flex: 1 1 50%;
          .host-picture {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
            flex: 0 0 52px;
            height: 52px;
            border-radius: 50%;
            background-color: #fff;
            overflow: hidden;
            border: 1px solid #01bdfa;
            .rtl & {
              margin-left: unset;
              margin-right: 10px;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
          span {
            font-weight: 600;
            font-size: 12px;
            line-height: 120%;
            text-align: center;
          }
        }
      }
      .sep {
        margin: 20px 0;
        width: 100%;
        height: 1px;
        background-color: #dde2eb;
      }

      .description {
        .title {
          font-size: 18px;
        }
        .text {
          margin: 15px 0 40px;
          font-size: 14px;
          font-weight: 400;
          word-break: break-word;
        }
      }
      .action-btns {
        display: flex;
        justify-content: space-between;
        button {
          width: 155px;
        }
        .btn-like {
          color: #fff;
          background: linear-gradient(180deg, #ff2222 0%, #eb8c57 100%);
        }
      }
    }
    .session-restritions {
      flex: 0.25;
      margin-left: 25px;
      .rtl & {
        margin-left: unset;
        margin-right: 25px;
      }
      h3 {
        font-size: 18px;
        font-weight: 600;
      }
      p {
        font-size: 16px;
        font-weight: 900;
        margin: 0;
      }
      span {
        font-size: 12px;
        font-weight: 500;
        max-width: 190px;
        display: inline-block;
      }
    }
  }
  .products-in-session {
    .custom-input {
      background-color: transparent;
      padding: 0;
      display: flex;
      .product-input {
        padding: 0;
        border: none;
        max-width: 360px;
      }
      .btn-primary {
        width: 85px;
        margin-left: 10px;
        border-radius: 5px;
        .rtl & {
          margin-left: unset;
          margin-right: 10px;
        }
      }
    }
    .table-height {
      height: calc((var(--vh, 1vh) * 100) - 415px);
      max-height: calc((var(--vh, 1vh) * 100) - 415px);
    }
    .store-table {
      display: flex;
      flex-direction: column;
      margin-top: 45px;
      .store-header {
        font-size: 12px;
        color: #aeaeae;
        font-weight: 500;
        margin-bottom: 10px;
        align-items: start;
        padding: 0 !important;
      }
      .table-height {
        max-height: 360px;
      }
      .accordion {
        width: 100%;
      }
      .store-row {
        display: flex;
        border-bottom: 1px solid #cccccc;
        padding: 24px 0;
        &:nth-last-child(1),
        &:first-child {
          border-bottom: none;
        }
        div {
          align-items: initial;
        }
        .card {
          border: none;
          border-radius: 0;
          .card-header {
            padding: 0;
            margin-bottom: 0;
            background-color: transparent;
            border-bottom: none;
            display: flex;
          }
          .card-body {
            flex: 1 1 auto;
            min-height: 1px;
            padding: 20px 0 0 0;
          }
        }
        .table-col {
          display: flex;
          align-items: center;
          .product-input {
            padding: 0 5px 0 14px;
            .rtl & {
              padding: 0 14px 0 5px;
            }
          }
          &:nth-child(1) {
            flex: 0.15;
            display: flex;
            img {
              all: unset;
            }
          }
          &:nth-child(2) {
            flex: 0.2;
            margin-left: 10px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            .rtl & {
              margin-left: unset;
              margin-right: 10px;
            }
            img {
              width: 56px;
              height: 59px;
              border-radius: 10px;
              margin-right: 15px;
              .rtl & {
                margin-right: unset;
                margin-left: 15px;
              }
            }
          }
          &:nth-child(3) {
            flex: 0.3;
            margin-left: 35px;
            .rtl & {
              margin-left: unset;
              margin-right: 35px;
            }
          }
          &:nth-child(4) {
            flex: 0.08;
            margin-left: 25px;
            .rtl & {
              margin-left: unset;
              margin-right: 25px;
            }
          }
          &:nth-child(5) {
            flex: 0.12;
            margin-left: 25px;
            .rtl & {
              margin-left: unset;
              margin-right: 25px;
            }
            button {
              width: 85px;
              border-radius: 5px;
            }
          }
          &:nth-child(6) {
            flex: 0.15;
            margin-left: 15px;
            .rtl & {
              margin-left: unset;
              margin-right: 15px;
            }
            button {
              // background-color: #dde2eb;
              border-radius: 10px;
              padding: 2px 9px;
            }
          }
          &:nth-child(7) {
            flex: 0.05;
            margin-left: 15px;
            .rtl & {
              margin-left: unset;
              margin-right: 15px;
            }
            button {
              float: right;
              .rtl & {
                float: left;
              }
              img {
                all: unset;
              }
            }
          }
        }
        .inner-table {
          display: flex;
          flex-direction: column;
          overflow-x: auto;
          .inn-header {
            color: #b3b3b3;
            .inn-col {
              justify-content: initial;
            }
          }
          .inn-row {
            flex: none;
            min-height: 75px;
            display: flex;
            align-items: center;
            .inn-col {
              flex: none;
              &:nth-child(1) {
                width: 35px;
                height: 35px;
                margin-right: 25px;
                .rtl & {
                  margin-right: unset;
                  margin-left: 25px;
                }
              }
              &.images {
                width: 230px;
                margin-right: 10px;
                display: flex;
                .rtl & {
                  margin-right: unset;
                  margin-left: 10px;
                }
                img {
                  width: 56px;
                  height: 59px;
                  border-radius: 10px;
                  margin-right: 15px;
                  .rtl & {
                    margin-right: unset;
                    margin-left: 15px;
                  }
                }
              }
              &.variant {
                width: 116px;
                margin-right: 10px;
                margin-left: 0;
                .rtl & {
                  margin-left: 10px;
                  margin-right: 0;
                }
              }
              &.quantity {
                width: 100px;
                margin-right: 10px;
                margin-left: 0;
                .rtl & {
                  margin-left: 10px;
                  margin-right: 0;
                }
              }
              &.price {
                width: 100px;
                margin-right: 10px;
                margin-left: 0;
                .rtl & {
                  margin-left: 10px;
                  margin-right: 0;
                }
              }
              &.sku {
                width: 50px;
                margin-left: 15px;
                .rtl & {
                  margin-left: unset;
                  margin-right: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
  .discounts {
    .nav-tabs {
      .discount-nav-tab {
        width: 300px;
        display: flex;
        height: 100%;
        margin-right: 20px;
        padding: 20px 25px 20px 10px;
        border: 3px solid transparent;
        .rtl & {
          margin-right: unset;
          margin-left: 20px;
          padding: 20px 10px 20px 25px;
        }
        &:hover {
          background-color: #f6f7f8;
        }
        .checkbox {
          display: flex;
          width: 70px;
          align-items: baseline;
          padding-top: 10px;
          background-image: url(../../assets/images/icons/checked_grey.svg);
          background-repeat: no-repeat;
          background-position: center 5px;
        }
        .discount-details {
          width: 200px;
          display: flex;
          flex-direction: column;
          color: #000;
          .discount-name {
            font-size: 16px;
            font-weight: 900;
          }
          .discount-description {
            font-size: 12px;
          }
        }
      }
      .active {
        background-color: #f6f7f8;
        border: 3px solid var(--primary-store);
        &::after {
          display: none;
        }
        & .checkbox {
          background-image: url(../../assets/images/icons/checked_blue.svg);
        }
      }
    }
    .sub-title-discuont-amount {
      display: flex;
      justify-content: flex-start;
      .discount-amount-space {
        margin-right: 130px;
      }
    }
    .discount-inner {
      margin-top: 50px;
      .custom-input {
        position: relative;
        background-color: transparent;
        padding: 0;
        width: initial;
        input {
          max-width: 110px;
          padding: 0 25px 0 14px;
          .rtl & {
            padding: 0 14px 0 25px;
          }
        }
        img {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          .rtl & {
            right: unset;
            left: 10px;
          }
        }
      }
      .warning {
        color: #ff6c4b;
        max-width: 135px;
        font-size: 14px;
        margin-left: 15px;
        .rtl & {
          margin-left: unset;
          margin-right: 15px;
        }
      }
    }
    .percentage-inner {
      margin-top: 50px;
      .percantage-discount-table {
        .discount-inputs {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          flex-direction: row;
          justify-content: space-around;
          &.discount-inputs-header {
            .label {
              width: 112px;
              margin-right: 26px;
              font-size: 16px;
              font-weight: 900;
              color: #00000030;
              .rtl & {
                margin-right: unset;
                margin-left: 26px;
              }
              &:last-child {
                margin-right: 0;
                .rtl & {
                  margin-right: unset;
                  margin-left: 0;
                }
              }
            }
          }
          .discount-input-group {
            display: flex;
            flex: 0 0 435px;
            margin-bottom: 20px;
            .rtl & {
              margin-right: unset;
              margin-left: 60px;
            }
            .custom-viewers-input {
              margin-right: 26px;
              .rtl & {
                margin-right: unset;
                margin-left: 26px;
              }
            }
          }
        }
      }
    }
    .item-inner {
      margin-top: 50px;
      .item-discount-table {
        .item-header-row {
          .i-col {
            color: #00000030;
          }
        }
        .item-row {
          display: flex;
          font-size: 16px;
          font-weight: 900;
          justify-content: flex-start;
          .i-col {
            width: 100%;
            margin-top: 10px;
            margin-bottom: 12px;
            // margin: 10px 30px 10px 0;
            display: flex;
            flex-direction: row;
            .rtl & {
              margin: 10px 0 10px 30px;
            }
            .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
              top: auto;
            }
            &:nth-child(3) {
              .custom-viewers-input {
                max-width: 360px;
              }
              // width: 360px;
            }
          }
        }
      }
    }
  }

  .mt-title {
    margin-top: 60px;
  }
  //////////////////////////////////////////
  // Inputs
  //////////////////////////////////////////
  label {
    font-size: 12px;
    font-weight: 500;
    color: #aeaeae;
  }
  .product-input {
    width: 100%;
    height: 40px;
    padding: 0 14px;
    border-radius: 5px;
    border: 1px solid #c4c4c4;
    outline: 0;
    font-size: 16px;
    font-weight: 500;
    // background-color: #F6F7FB;
    &::placeholder {
      color: #949ba5;
    }
    &.custom-input-lg {
      height: 50px;
      padding: 0 16px;
      font-size: 16px;
    }
  }
  .invalid,
  input.ng-invalid.ng-touched,
  textarea.ng-invalid.ng-touched {
    position: relative;
    border-color: $danger;
    padding-right: 30px;
    background-image: url('/assets/images/icons/input-invalid-icon.svg');
    background-size: 20px;
    background-position: calc(100% - 8px) center;
    background-repeat: no-repeat;
    background-color: rgba(235, 87, 87, 0.2);
    border: 1px solid $danger;
    .rtl & {
      background-position: calc(0% + 8px) center;
    }
  }
  ///Video:////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  .mb-4 {
    width: 201px;
    height: 28px;
    left: 369px;
    top: 3752px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
  }
  .details-video {
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #9e9ea9;
    // margin-right: 8%;
  }
  .panelVideo:hover {
    background: #fbfbfb;
    // border: 1px solid #F3F3F3;
    border-radius: 6px;
  }
  .videoFrom {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    align-items: baseline;
    flex-direction: row;
    .videoLink {
      padding: 0 5px 0 10px;
      margin-bottom: 10px;
      width: 371px;
      height: 35px;
      background: #ebebeb;
      border-radius: 6px;
      border: 1px solid #c4c4c4;
    }
    .invalid-feedback {
      display: block;
      margin-bottom: 0;
      color: $danger;
      font-size: 14px;
      line-height: 20px;
    }
    .videoOrMp4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #333838;
      margin-right: 40px;
      margin-left: 40px;
      margin-top: 1%;
    }
    .uploadMp4 {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: var(--primary-store);
      color: #ffffff;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      padding: 9px 25px;
      font-size: 16px;
      font-weight: 600;
    }
    .VideoMp4 {
      width: 170px;
      height: 35px;
      left: 106px;
      margin-left: 4px;
      padding: 0 5px 0 10px;
      background: #ebebeb;
      border-radius: 6px;
      padding: 0 5px 0 10px;
      border: 1px solid #c4c4c4;
    }
    .select-item {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-around;
      margin-right: 10px;
    }
    .select-item-header {
      margin-right: 6px;
      margin-bottom: 2px !important;
    }
  }
  .panelVideo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .deleteBtn {
      display: flex;
      justify-content: center;
      width: 180px;
      height: 35px;
      background: #f96544;
      border-radius: 60px;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
  .add-Video {
    position: absolute;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    align-items: flex-start;
    color: var(--primary-store);
    display: flex;
    align-items: center;
    background: #f3f3f3;
    border-radius: 60px;
    width: 120px;
    height: 35px;
    margin: 3%;
    justify-content: space-around;
    margin-left: auto;
  }
  .container {
    width: 145%;
    margin-top: 3%;
    .add-Video {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 9px 25px;
      background: #f3f3f3;
      border-radius: 60px;
      width: 120px;
      height: 35px;
    }
    .importVideo {
      position: inherit;
      font-size: 16px;
      line-height: 20px;
      color: #333838;
      margin-bottom: 1%;
    }
    .videoFrom {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: nowrap;
      align-content: flex-start;
      align-items: flex-start;
      .videoOrMp4 {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #333838;
        margin-right: 40px;
        margin-left: 40px;
        margin-top: 1%;
      }

      .videoLink {
        padding: 0 5px 0 10px;
        margin-bottom: 10px;
        width: 371px;
        height: 35px;
        background: #ebebeb;
        border-radius: 6px;
        border: 1px solid #c4c4c4;
      }
      .VideoMp4 {
        width: 170px;
        height: 35px;
        left: 106px;
        margin: 1%;
        padding: 0 5px 0 10px;
        margin-bottom: 3%;
        background: #ebebeb;
        border-radius: 6px;
        padding: 0 5px 0 10px;
        border: 1px solid #c4c4c4;
      }
    }
    .video {
      width: 100%;
      opacity: 1;
      margin: 3px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      place-content: SPACE-BETWEEN;
      .imgAndInfoVideo {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: nowrap;
      }
      .infoVideo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        .video-and-link {
          max-width: 70%;
        }
      }
      .videoImg {
        width: 157px;
        height: 102px;
        left: 371px;
        top: 3928px;
        background: #000000;
        border-radius: 5px;
        margin-right: 3%;
      }
      .mb-4 {
        display: contents;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: stretch;
        align-items: baseline;
        justify-content: center;
      }
    }
    .errVideoUrl {
      width: 196px;
      height: 15px;
      left: 370px;
      top: 4138px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #f96544;
    }
    .add-Video {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: var(--primary-store);
      margin-top: 3%;
    }
  }
}

// switvher

.toggleSwitch-container {
  position: relative;
  height: 80px;
  width: 115px;
  border-radius: 35px;
  top: -15px;
  left: -43px;
  border-bottom-left-radius: 0;

  .toggleSwitch-title {
    position: absolute;
    font-size: 16px;
    left: 101px;
    top: 45px;
    width: 200px;
  }

  .toggleSwitch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    top: 37px;
    left: 33px;
  }

  .toggleSwitch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .toggleSlider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .toggleSlider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  input:checked + .toggleSlider {
    background-color: var(--primary-store);
  }
  input:focus + .toggleSlider {
    box-shadow: 0 0 1px var(--primary-store);
  }
  input:checked + .toggleSlider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  .toggleSlider.toggleRound {
    border-radius: 34px;
  }
  .toggleSlider.toggleRound:before {
    border-radius: 50%;
  }
}
#select-video {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#video-container {
  align-items: flex-end;
}
#general-video {
  margin-top: 1.5rem !important;
}
