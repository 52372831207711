.import-items-textarea {
  resize: none;
  width: 100%;
  height: 200px;
  padding: 14px;
  border-radius: 20px;
  border: 1px solid #c4c4c4;
  outline: 0;
  font-size: 16px;
  margin-bottom: 14px;
}

.import-drop-zone {
  width: 100%;
  min-height: 200px;
  border: 1px dashed #b3b3b3;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  position: relative;
  margin-bottom: 20px;
}

.import-drop-zone:hover,
.import-drop-zone.dragover {
  background: #f8f8f8;
  border-color: #58575a;
}

.import-upload-file-button {
  padding: 10px 20px;
  border: 1px solid #b3b3b3;
  border-radius: 22.5px;
  background: #fff;
  font-size: 16px;
  color: #58575a;
  text-align: center;
}

.import-upload-file-button:hover {
  background: #000;
  color: white;
}

.import-num-products {
  color: #58575a;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid #e1e0e0;
  margin: 60px 0;
}

.import-icon {
  max-width: 10%;
  padding: 20px;
  border-radius: 20px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transform: rotate(-45deg);
  }
}

.import-info {
  max-width: 70%;
  padding-left: 50px;

  .filename {
    font-size: 20px;
    font-weight: 500;
  }
}

.import-button {
  max-width: 20%;
  padding: 20px;

  .import-upload-file-button {
    cursor: pointer;
  }
}

.import-icon,
.import-info,
.import-button {
  flex: 1;
}

.import-info {
  display: flex;
  flex-direction: column;
}

.sample-import-file a {
  position: absolute;
  color: #0e86d4;
}

.store-adding-products {
  margin-top: -30px;
  padding-bottom: 120px;
  .bg-block {
    border-radius: 10px;
    background-color: #c4c4c410;
  }

  h2 {
    font-weight: 600;
    font-size: 34px;
    margin-bottom: 30px;
  }
  h3 {
    font-size: 25px;
    font-weight: 500;
    line-height: 108.6%;
    letter-spacing: 0.005em;
    margin: 30px 0 15px;
  }
  .product-details {
    display: flex;
    .product-description {
      flex: 0.6;
      label[for='description'] {
        margin-top: 30px;
      }
    }
    .product-photos {
      flex: 0.4;
      margin: 20px 0 0 30px;
      width: 100%;
      position: relative;
      display: flex;
      align-items: flex-end;
      padding: 25px;
      .rtl & {
        margin: 20px 30px 0 0;
      }

      .photos-inner {
        position: absolute;
        top: 25px;
        bottom: 125px;
        left: 25px;
        right: 25px;
        border-radius: 10px;
        border: 1px solid #c4c4c4;
        background-color: #fff;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;
        overflow: auto;

        ngx-file-drop {
          width: 100%;
        }
        .product-photo {
          border-radius: 10px;
          height: 86px;
          width: 86px;
          margin: 0 5px 20px 5px;
          position: relative;
          display: flex;
          justify-content: center;
          border: 2px solid transparent;

          img {
            max-width: 84px;
            max-height: 84px;
            border-radius: 10px;
            object-fit: contain;
            object-position: center;
          }

          .remove-image-btn {
            display: none;

            img {
              position: absolute;
              top: 4px;
              right: 4px;
              width: 17px;
              height: 16px;
              .rtl & {
                right: unset;
                left: 4px;
              }
            }
          }

          &:hover {
            border: 2px solid #dde2eb;

            .remove-image-btn {
              display: block;
              position: absolute;
              min-width: initial;
              right: 0;
              .rtl & {
                right: unset;
                left: 0;
              }
            }
          }
        }
      }
      .chose-btn {
        width: 100%;
        min-width: 352px;
        margin-top: 15px;
        margin-bottom: 10px;
        color: #fff;
        background-color: #0fd9f4;
      }
      span {
        color: #aeaeae;
        font-size: 12px;
        font-weight: 900;
      }
    }
  }
  .inventory-block {
    width: 100%;
    display: flex;
    padding: 30px 55px 25px 30px;

    .rtl & {
      padding: 30px 30px 25px 55px;
    }

    .form-group {
      max-width: 220px;
    }
    label {
      width: 100%;
    }
    #sku {
      max-width: 200px;
      img {
        margin-bottom: 2px;
      }
    }
    #barcode {
      max-width: 95px;
      img {
        margin-bottom: 2px;
      }
    }
    .tooltip-inner {
      padding: 15px;
      max-width: 120px;
      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
      border-radius: 1px;
      font-size: 10px;
      font-weight: 500;
      color: #000;
      text-align: left;
    }
    .inventory-description {
      padding-top: 10px;
      color: #00000040;
      font-size: 9px;
      font-weight: 500;
    }
  }
  .pricing-details {
    padding: 20px 30px 20px 16px;

    .rtl & {
      padding: 20px 16px 20px 30px;
    }

    .form-row {
      flex-wrap: nowrap;
      label {
        margin-top: 0;
        padding: 0 8px 0 14px;

        .rtl & {
          padding: 0 14px 0 8px;
        }
      }
      .custom-input {
        position: relative;
        background-color: transparent;
        width: 125px;
        .currency-sign {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          .rtl & {
            right: unset;
            left: 20px;
          }
        }

        input {
          max-width: 100px;
          padding-right: 40px;
          .rtl & {
            padding-right: 14px;
            padding-left: 20px;
          }
        }
      }
      .profit {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 0 0 10px;
        font-size: 12px;
        font-weight: 900;
        color: #aeaeae;
        .rtl & {
          padding: 0 10px 0 0;
        }
        span {
          color: #000;
        }
      }
    }
  }
  .mt-title {
    margin-top: 60px;
  }
  .shipping-details {
    display: flex;

    .shipping-options {
      max-width: 528px;
      display: flex;
      flex-direction: column;
      flex: 0.6;
      padding: 30px 70px 16px 30px;

      .rtl & {
        padding: 30px 30px 16px 70px;
      }

      .shipping-option {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        justify-content: space-between;
      }
    }
    .shipping-availability {
      display: flex;
      flex: 0.4;
      width: 100%;
      margin-left: 30px;
      padding: 15px 25px 25px 25px;

      .rtl & {
        margin-left: unset;
        margin-right: 30px;
      }

      label {
        padding: 0 0 0 50px;
        line-height: 1;
        max-width: 225px;

        .rtl & {
          padding: 0 50px 0 0;
        }

        span {
          margin-top: 5px;
          display: inline-block;
          color: #00000040;
          font-size: 9px;
          font-weight: 500;
        }
      }
    }
  }
  .variants {
    padding: 20px 25px 40px 30px;

    .rtl & {
      padding: 20px 30px 40px 25px;
    }

    .form-row {
      flex-wrap: nowrap;
    }
    .option-value {
      width: 100%;
      max-width: 665px;
      padding: 0;
      border: none;
    }
    .option-name {
      max-width: 190px;
    }
    .form-group {
      .custom-input {
        background-color: transparent;
        padding: 0 25px 0 0;

        .rtl & {
          padding: 0 0 0 25px;
        }

        .ng-arrow {
          display: none;
        }
      }
      span {
        font-size: 16px;
      }
      .validate {
        color: #aeaeae;
        float: right;
        font-size: 12px;
        font-weight: 500;
        padding: 4px 25px 0 0;
        .rtl & {
          padding: 4px 0 0 25px;
        }
      }
      .ng-select-container {
        height: 40px;
      }
    }

    .custom-admin-btn {
      font-size: 13px;
    }
  }

  //////////////////////////////////////////
  // Customization section
  //////////////////////////////////////////
  .customization-section {
    padding: 20px 0;

    .big-box-tabs {
      margin-top: 40px;
    }

    .product-textarea {
      height: 119px;
    }

    .preview-container {
      padding: 15px;
      border-radius: 10px;
      background-color: #c4c4c410;
      .preview-description {
        word-break: normal;
      }
      .small-text {
        font-size: 12px;
      }
    }
  }

  //////////////////////////////////////////
  // Inputs
  //////////////////////////////////////////
  label {
    font-size: 12px;
    font-weight: 500;
  }

  .product-input {
    width: 100%;
    height: 40px;
    padding: 0 14px;
    border-radius: 5px;
    border: 1px solid #c4c4c4;
    outline: 0;
    font-size: 14px;
    font-weight: 500;

    &::placeholder {
      color: #949ba5;
    }

    &.custom-input-lg {
      height: 50px;
      padding: 0 16px;
      font-size: 16px;
    }
  }

  .product-textarea {
    resize: none;
    width: 100%;
    height: 125px;
    padding: 14px;
    border-radius: 5px;
    border: 1px solid #c4c4c4;
    outline: 0;
    font-size: 16px;
    font-weight: 500;
  }
  .invalid,
  input.ng-invalid.ng-touched {
    position: relative;
    border-color: $danger;
    padding-right: 30px;
    background-image: url('/assets/images/icons/input-invalid-icon.svg');
    background-size: 20px;
    background-position: calc(100% - 8px) center;
    background-repeat: no-repeat;
    background-color: rgba(235, 87, 87, 0.2);
    border: 1px solid $danger;

    .rtl & {
      padding-right: unset;
      padding-left: 30px;
      background-position: calc(0% + 8px) center;
    }
  }
  .invalid-feedback {
    display: block;
    color: $danger;
    font-size: 14px;
    line-height: 20px;
  }

  .checkbox-input label {
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
}
