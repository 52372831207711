.add-new-embedded {
  &.poll {
    .modal-dialog {
      max-width: calc(100% - 330px);
      .modal-content {
        padding-left: 54px;
        padding-right: 54px;
      }
    }
  }
  .modal-dialog {
    max-width: calc(100% - 346px);
    margin: 0;
    margin-left: auto;
    top: 58px;
    min-height: auto;
    .modal-content {
      max-width: calc(100vw - 415px);
      min-width: 740px;
      min-height: 490px;
      border-radius: 8px;
      padding-top: 30px;
      padding-left: 67px;
      padding-right: 40px;
      padding-bottom: 20px;
      background: #fff;
      height: calc(100vh - 160px);
      .btn-add-question {
        border-radius: 29px !important;
        text-transform: capitalize;
        font-size: calc(0.63vh + 0.63vw);
        height: 4.7vh;
      }
      .btn-popup-embed {
        border-radius: 29px;
        text-transform: capitalize;
        width: 10vw;
        height: 4.7vh;
        margin-left: auto;
        font-size: calc(0.63vh + 0.63vw);
      }
      .btn-add-poll {
        border-radius: 29px;
        text-transform: capitalize;
        width: 10vw;
        height: 4.7vh;
        font-size: calc(0.63vh + 0.63vw);
      }
      .btn-add-option {
        background: none;
        padding: 0 !important;
        font-size: 14px;
      }
    }
  }
}

.script-already-exists {
  background: rgba(101, 101, 101, 0.9);
  .modal-dialog {
    width: 320px;
    .modal-content {
      padding: 16px;
      .btn-unsaved-changes-confirm {
        background: #f5a161;
      }
      .btn-generate-new-script {
        background: #f5a161;
        text-transform: none;
        border-radius: 8px;
        height: 41px;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 11.422px;
        letter-spacing: 0.56px;
      }
      .btn-generate-view-original {
        height: 41px;
        text-transform: none;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        &.open {
          border-radius: 10px 10px 0px 0px;
        }
      }
    }
  }
}
