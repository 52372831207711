//////////////////////////////////////////
// Inputs
//////////////////////////////////////////
.custom-input {
  width: 100%;
  height: 40px;
  padding: 0 14px;
  border-radius: 10px;
  font-size: 14px;
  border: 0;
  outline: 0;
  background-color: #f6f7fb;

  &::placeholder {
    color: #949ba5;
  }

  &.custom-input-lg {
    height: 50px;
    padding: 0 16px;
    font-size: 16px;
  }
}

.invalid,
input.ng-invalid.ng-touched {
  position: relative;
  border-color: $danger;
  padding-right: 30px;
  background-image: url('/assets/images/icons/input-invalid-icon.svg');
  background-size: 20px;
  background-position: calc(100% - 8px) center;
  background-repeat: no-repeat;
  background-color: rgba(235, 87, 87, 0.2);
  border: 1px solid $danger;

  .rtl & {
    padding-right: 16px;
    padding-left: 30px;
    background-position: calc(0% + 8px) center;
  }
}

.invalid-feedback {
  display: block;
  color: $danger;
  font-size: 14px;
  line-height: 20px;
}
.accept-feedback {
  display: block;
  color: #f39449;
  font-size: 14px;
  line-height: 20px;
}

.input-group {
  > .custom-input {
    display: inline-block;
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    .rtl & {
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .input-group-append {
    button {
      padding: 0 5px;
      border-radius: 0 10px 10px 0;
      font-size: 20px;
      border: 0;
      outline: 0;
      background-color: #f6f7fb;

      .rtl & {
        border-radius: 0 0 10px 10px;
      }
    }
  }
}

//////////////////////////////////////////
// Remove arrows from number field
//////////////////////////////////////////
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

//////////////////////////////////////////
// Radio buttons
//////////////////////////////////////////
.custom-control.custom-radio {
  padding-left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .rtl & {
    padding-left: unset;
    padding-right: 0;
    margin-left: 0;
  }

  .custom-control-label {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    padding: 0 8px;
    width: 100%;
    height: 50px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    border-radius: 10px;
    background-color: #fff;
    cursor: pointer;
    justify-content: space-between;
    .rtl & {
      padding-right: 16px;
      padding-left: 56px;
    }

    &:before {
      top: 13px;
      left: auto;
      right: 16px;
      display: none;
      .rtl & {
        right: auto;
        left: 16px;
      }
    }

    &:after {
      top: 13px;
      left: auto;
      right: 16px;
      .rtl & {
        right: auto;
        left: 16px;
      }
    }

    .icon {
      margin-right: 16px;
      width: 24px;
      height: 24px;
      .rtl & {
        margin-right: unset;
        margin-left: 16px;
      }
    }
  }

  .checked {
    background-color: #f6f7fb;
  }
}

//////////////////////////////////////////
// Custom payment radio button
//////////////////////////////////////////
.custom-control.custom-radio.custom-radio-payment-option {
  .custom-control-label {
    position: relative;
    margin-bottom: 10px;
    padding-left: 100px;
    width: 100%;
    height: 60px;
    text-align: left;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    background-color: #000;
    background-image: url('/assets/images/payment-button-bg.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: left top;
    border-radius: 15px;

    .rtl & {
      padding-left: unset;
      padding-right: 100px;
      background-image: url('/assets/images/payment-button-bg-rtl.svg');
    }

    &:before {
      top: 18px;
      left: auto;
      right: 16px;
      .rtl & {
        left: 16px;
        right: auto;
      }
    }

    &:after {
      top: 18px;
      left: auto;
      right: 16px;
      .rtl & {
        left: 16px;
        right: auto;
      }
    }

    .company-icon {
      position: absolute;
      top: 12px;
      left: 16px;
      width: 58px;
      height: 36px;
      object-fit: contain;
      object-position: center;
      .rtl & {
        left: unset;
        right: 16px;
      }
    }
  }
  .custom-control-input:checked ~ .custom-control-label {
    background-color: #000;
  }
}

//////////////////////////////////////////
// Phone + prefix RTL to LTR fix
//////////////////////////////////////////
.form-row.form-row-phone {
  .rtl & {
    flex-direction: row-reverse;
  }
}
