/* heebo-300 - hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 300;
  src: local(''), url('../assets/fonts/heebo/heebo-v12-hebrew-300.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../assets/fonts/heebo/heebo-v12-hebrew-300.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+590-5ff;
}
/* heebo-regular - hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../assets/fonts/heebo/heebo-v12-hebrew-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../assets/fonts/heebo/heebo-v12-hebrew-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+590-5ff;
}
/* heebo-500 - hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('../assets/fonts/heebo/heebo-v12-hebrew-500.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../assets/fonts/heebo/heebo-v12-hebrew-500.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+590-5ff;
}
/* heebo-600 - hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 600;
  src: local(''), url('../assets/fonts/heebo/heebo-v12-hebrew-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../assets/fonts/heebo/heebo-v12-hebrew-600.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+590-5ff;
}
/* heebo-700 - hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('../assets/fonts/heebo/heebo-v12-hebrew-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../assets/fonts/heebo/heebo-v12-hebrew-700.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+590-5ff;
}
