.entrance-fee-modal {
  .modal-dialog {
    @include media-breakpoint-down(sm) {
      margin: 10px;
      width: auto;
    }
    .modal-content {
      app-entrance-fee-modal {
        ngx-stripe-card-group {
          display: block;
          margin-top: 20px;
        }

        .custom-input {
          margin-bottom: 10px;
        }

        .input-wrapper {
          display: inline-table;
          margin-bottom: 10px;
          width: 100%;
          height: 50px;
          padding: 16px;
          border-radius: 10px;
          border: 0;
          background-color: #f6f7fb;
          &.half {
            width: calc(50% - 5px);
            &.with-margin {
              margin-left: 10px;
              .rtl & {
                margin-left: unset;
                margin-right: 10px;
              }
            }
          }
        }

        .btn-submit {
          width: 100%;
          &.btn-save-card {
            margin-top: 20px;
          }
        }
      }

      .legal-text {
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
      }

      .btn-confirm {
        width: 100%;
      }

      .checkout-iframe-container {
        iframe {
          border: 0;
          min-height: calc(var(--vh, 1vh) * 70);
        }
      }
    }
  }
}
