.rtl .live-session-host-mobile .right-side-column .list-view-item {
  left: 11vw;
  right: unset;
}
.live-session-host-mobile {
  position: relative;
  height: 100%;
  width: 100%;
  //////////////////////////////////////////
  // Camera Feed
  //////////////////////////////////////////
  .camera-feed-wrapper {
    position: relative;
    align-self: flex-start;
    height: 100%;
    width: 100%;
    overflow: hidden;
    .camera-placeholder-video {
      width: 100%;
      height: 100%;
    }
    .camera-feed {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    .bottom-gradient {
      position: absolute;
      bottom: 0;
      z-index: 2;
      height: 150px;
      width: 100%;
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }
  .top-row {
    position: absolute;
    top: 6px;
    left: 6px;
    z-index: 3;
    display: flex;
    flex-direction: column;

    .rtl & {
      left: unset;
      right: 6px;
    }
    .badge-live {
      display: flex;
      align-items: center;
      margin-right: 10px;
      padding: 0 6px;
      height: 34px;
      border-radius: 17px;
      .rtl & {
        margin-right: unset;
        margin-left: 10px;
      }
      &.is-brodcasting {
        &::after {
          content: '•';
          color: #ff4b33;
          font-weight: 600;
          font-size: 22px;
          line-height: 1;
        }
      }
      .avatar {
        margin-right: 10px;
        width: 22px;
        aspect-ratio: 1;
        border: 1px solid #fff;
        overflow: hidden;
        border-radius: 50%;
        line-height: 1;
        .rtl & {
          margin-right: unset;
          margin-left: 10px;
        }
        .host-picture {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .host-name {
        margin-right: 10px;
        font-weight: 500;
        font-size: 11px;
        line-height: 1;
        color: #fff;
        max-width: 10rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .rtl & {
          margin-right: unset;
          margin-left: 10px;
        }
      }
    }
    .badge-views {
      display: flex;
      align-items: center;
      margin-right: 10px;
      padding: 0 8px;
      border-radius: 17px;
      .rtl & {
        margin-right: unset;
        margin-left: 10px;
      }
      .icon {
        margin-right: 8px;
        width: 10px;
        .rtl & {
          margin-right: unset;
          margin-left: 8px;
        }
      }
      .views {
        font-size: 11px;
        line-height: 1;
        color: #fff;
      }
    }
    .btn-mute {
      img {
        width: 34px;
      }
    }
  }
  .right-side-column {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 3;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    .rtl & {
      right: unset;
      left: 6px;
    }
    .list-view-item {
      display: flex;
      flex-direction: column;
      height: auto;
      &::-webkit-scrollbar {
        /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      .btn-view-item {
        width: 17vw;
        min-width: 50px;
        position: relative;
        cursor: pointer;
        text-align: center;
        img {
          height: 15vw;
          min-height: 50px;
        }
      }
    }
    .btn-view-item {
      width: 27vw;
      color: #fff;
      font-size: 10px;
      line-height: 20px;
      border-radius: 6px;
      background-color: rgba(#000, 0.4);
      z-index: 3;
      text-transform: uppercase;
      img {
        width: 100%;
        height: 30vw;
        background-color: #fff;
        border-radius: 6px;
        object-fit: contain;
        object-position: center;
        object-fit: cover;
      }
      .remove-view-item {
        position: absolute;
        right: 2px;
        top: 2px;
      }
    }
    .added-to-cart {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      margin-top: 3px;
      padding: 0 6px;
      height: 25px;
      width: 27vw;
      font-size: 11px;
      line-height: 1;
      color: #fff;
      border-radius: 6px;
      background-color: rgba(#000, 0.4);
      justify-content: center;
      img {
        margin-right: 5px;
        font-size: 20px;
        .rtl & {
          margin-right: unset;
          margin-left: 5px;
        }
      }
    }
    .definitions-btn {
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
    }

    .camera-change-btn {
      position: relative;
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 50%;
      backdrop-filter: blur(10px);
    }
    //////////////////////////////////////////
    // Discount Coupon
    //////////////////////////////////////////
    .discount-coupon-value {
      font-weight: 700;
      font-size: 10px;
      line-height: 1;
      z-index: 3;
      color: var(--light-store);
    }
    .host-buttons-wrapper{
      gap: 16px;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-right: 2px;
    }
    //////////////////////////////////////////
    // Prograss bar
    //////////////////////////////////////////
    .progress-bar {
      position: relative;
      z-index: 1;
      width: 38px;
      height: 220px;
      text-align: center;
      background-color: #dcdcdc;
      border-radius: 6px;
      overflow: hidden;
      .rtl & {
        margin: 20px auto 0 0;
      }
      .btn-view-item {
        position: absolute;
        top: 31px;
        width: 34px;
        height: 34px;
        border-radius: 5px;
        background-color: #fff;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
        &.unavailable {
          &::after {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            background-image: url('/assets/images/lock.svg');
            background-position: center;
            background-repeat: no-repeat;
            pointer-events: none;
            background-size: 28%;
            z-index: 1;
          }
        }
      }
      .goal-coupon {
        position: absolute;
        top: 2px;
        left: 2px;
        right: 2px;
        margin: 0;
      }
      .progress-bar-fill {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        min-height: 20px;
        max-height: calc(100% - 58px);
        background: linear-gradient(180deg, var(--secondary-store) 10%, var(--primary-store) 100%);
        transition: height 2000ms ease-out;
        // When we have a coupon at the bottom
        &.coupon-reached {
          min-height: 44px;
        }
        // When reached 100% precent
        &.max-reached {
          max-height: none;
          // lower the logo with text
          .floater {
            transform: translate(0, 58px);
          }
        }
        // When under 100% precent with space reserved for .goal-discount-product
        &.with-item-discount {
          max-height: calc(100% - 10vw);
          &.coupon-reached {
            min-height: 70px;
          }
          &.max-reached {
            max-height: none;
            .floater {
              transform: translate(0, 80px);
            }
          }
        }

        .floater {
          position: absolute;
          top: -12.5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            background: #ffffff;
            box-shadow: 0 0.556vw 0.694vw rgba(184, 107, 249, 0.2);
            border-radius: 50%;
            img {
              width: 8px;
            }
          }
        }
      }
      .progress-bar-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 3;
        display: flex;
        flex-direction: column;
        align-items: center;

        .value {
          padding-top: 6px;
          font-size: 11px;
          background: linear-gradient(180deg, var(--secondary-store) 0%, var(--primary-store) 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 700;
        }

        .btn-view-item {
          position: static;
          margin: 0 auto 2px;
        }
      }
    }
  }

  //////////////////////////////////////////
  // Products pull up
  //////////////////////////////////////////
  .pull-up-container.products-pull-up {
    .pull-up-content {
      .pull-up-body {
        background-color: #ffffff;
        .products-wrapper {
          .products {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            padding: 0 10px;
            width: 100%;
            z-index: 1;
            flex-direction: column;
          }
          .products-mobile {
            gap: 8px;
          }
          .product-separator {
            justify-content: center;
          }
          .separator {
            width: 90vw;
            height: 1px;
            background: #dfe2eb;
          }
        }
      }
    }
  }
  //////////////////////////////////////////
  // Host pull up
  //////////////////////////////////////////
  .pull-up-container.host-pull-up {
    .pull-up-content {
      display: flex;
      flex-direction: column;
      height: 100%;

      .pull-up-head {
        flex: 0;
      }

      .pull-up-body {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 0 10px;

        .action-tabs-nav {
          flex: 0;
          overflow-x: unset;
        }

        .action-tab-content {
          flex: 1;
          height: 100%;

          .tab-pane {
            height: 100%;

            .tab-inner {
              height: 100%;
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }

    //////////////////////////////////////////
    // Polls
    //////////////////////////////////////////

    .poll__answers,
    .poll__voters {
      flex-basis: 100%;
    }

    //////////////////////////////////////////
    // Videos
    //////////////////////////////////////////

    .playIcon {
      margin-top: 74px;
      opacity: 1;
      height: 23%;
      align-self: center;
      inline-size: -webkit-fill-available;
    }
    .video-item {
      // border: double;
      border-radius: 1.625vw;
      margin: 1rem;
      flex-direction: column;
      align-items: flex-start;
      // justify-content: center;
      align-content: flex-start;
      word-break: break-word;
      background-repeat: no-repeat;
      /* background-attachment: fixed; */
      background-size: 101% 100%;
      width: 156px;
      height: 314px;
      left: 545px;
      top: 137px;
      display: flex;
      display: inline-table;
      // justify-content: flex-end;
      justify-content: space-between;
    }
    .videoName {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
      margin-bottom: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
      color: #ffffff;
      white-space: break-spaces;
    }
    .videoLength {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      display: flex;
      align-items: center;
      margin: 0px;
    }
    .infoVideo {
      display: flex;
      flex-direction: column;
      width: 167px;
      padding: 8px;
      align-items: flex-start;
    }
    .product-item {
      border: double;
      border-radius: 1.625vw;
      display: inline-block;
      margin: 1rem;
    }
    .video-cards-mobile {
      position: relative;
      padding: 0.417vw;
      flex: 0 0 calc(25% - 0.694vw);
      transition: all 300ms ease-out;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      min-height: 13em;
      border-radius: 10px;
      width: 100%;
      background-size: inherit;
      background-repeat: repeat-x;
      flex-wrap: wrap;
      align-content: space-around;
      justify-content: space-around;
      width: 160px;
      height: 238px;
    }
    .containerAllVideos {
      flex-grow: 0 !important;
      overflow: overlay;
      white-space: pre;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .videoInSession {
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
    }
    .ng-scroll-content {
      display: inline-flex;
      flex-wrap: wrap;
    }

    //////////////////////////////////////////
    // Analytics
    //////////////////////////////////////////
    .stats-container {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      width: 100%;
      .stat-item {
        flex: 0 1 calc(100% / 2);
        margin-bottom: 2.083vw;
        padding-right: 1.389vw;
        font-weight: 700;
        font-size: 20px;
        line-height: 1.5;
        .rtl & {
          padding-right: unset;
          padding-left: 1.389vw;
        }
        .stat-value {
          color: var(--primary-store);
        }
      }
    }
  }
  //////////////////////////////////////////
  // Poll Expanded pull up
  //////////////////////////////////////////
  .pull-up-container.poll-expanded-pull-up {
    .pull-up-content {
      .pull-up-head {
        display: flex;
        align-items: center;
        padding-top: 50px;
        padding-bottom: 30px;
        .product-name {
          flex-grow: 1;
          text-align: center;
          font-weight: 300;
          font-size: 20px;
          @extend .line-clamp-1;
        }
        .product-price {
          margin-left: auto;
          margin-right: 20px;
          font-weight: 300;
          font-size: 20px;
          .rtl & {
            margin-left: 20px;
            margin-right: auto;
          }
        }
      }
      .pull-up-body {
        padding: 0 20px;
        .expanded-product-container {
          .expanded-product-body {
            .swiper {
              width: 100%;
              .product-image {
                position: relative;
                width: 100%;
                border-radius: 16px;
                overflow: hidden;
                &::before {
                  display: block;
                  content: '';
                  padding-top: 100%;
                }
                img {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                  object-position: center;
                  .rtl & {
                    left: unset;
                    right: 0;
                  }
                }
              }
              .swiper-pagination {
                top: 10px;
                right: 10px;
                left: auto;
                bottom: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 8px;
                width: auto;
                background: #eee;
                border-radius: 11px;
                z-index: 3;
                transform: none;
                .rtl & {
                  left: 10px;
                  right: auto;
                }
                .swiper-pagination-bullet {
                  margin: 0 2px;
                  width: 6px;
                  height: 6px;
                  border: 1px solid #28374b;
                  border-radius: 50%;
                  background-color: transparent;
                  opacity: 1;
                  &.swiper-pagination-bullet-active {
                    background-color: #28374b;
                  }
                }
              }
            }
          }
          .expanded-product-footer {
            .btn-vote {
              margin-top: 20px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
