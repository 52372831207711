.custom-tooltip {
  .tooltip-inner {
    box-shadow: 0 0.278vw 1.389vw rgba(#030c23, 0.1);
    border-radius: 1.042vw;
  }

  &.arrow::before {
  }

  &.discount-tooltip {
    .tooltip-inner {
      padding: 8px 10px;
      max-width: none;
      .rtl & {
        margin-right: 50px;
      }
      @include media-breakpoint-up(xl) {
        padding: 0.833vw;
      }

      .discount-details {
        font-weight: 600;
        font-size: 12px;
        line-height: 1;
        color: #000;
        text-align: left;
        @include media-breakpoint-up(xl) {
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 1.042vw;
        }

        .rtl & {
          text-align: right;
        }

        .views-badge {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          padding: 1px 3px 2px 2px;
          background-color: #000;
          border-radius: 2px;

          .rtl & {
            padding: 1px 2px 2px 3px;
          }
        }

        .discount-coupon {
          margin-right: 0.694vw;

          .rtl & {
            margin-right: unset;
            margin-left: 0.694vw;
          }

          .value {
            font-weight: 600;
            font-size: 1.042vw;
            line-height: 1;
            color: var(--secondary-store);
          }
        }
      }
    }
  }
}
