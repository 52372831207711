//////////////////////////////////////////
// Single Product pull up
//////////////////////////////////////////
.see-all-item-title {
  margin: 0px;
  left: 8vw;
  position: absolute;
  color: var(--primary-store);
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin-top: 10px;
}
.pull-up-container.product-pull-up {
  .pull-up-content {
    .pull-up-head {
      margin-bottom: 24px;
    }
    .pull-up-body {
      //////////////////////////////////////////
      // Product
      //////////////////////////////////////////
      .product-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20%;
        .swiper {
          width: 100%;
          .product-image {
            position: relative;
            width: 100%;
            border-radius: 16px;
            overflow: hidden;
            &::before {
              display: block;
              content: '';
              padding-top: 100%;
            }

            img {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;

              .rtl & {
                left: unset;
                right: 0;
              }
            }

            .sold-out-badge {
              position: absolute;
              top: 10px;
              left: 10px;
              padding: 6px 12px;
              background: #f96544;
              border-radius: 20px;
              color: #ffffff;
              font-size: 12px;
              font-weight: 700;
              line-height: 120%;
              display: flex;
              align-items: center;
              letter-spacing: 0.04em;
              text-transform: uppercase;

              .rtl & {
                left: unset;
                right: 10px;
              }
            }
          }

          .swiper-pagination {
            top: 10px;
            right: 10px;
            left: auto;
            bottom: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px;
            width: auto;
            background: #eee;
            border-radius: 11px;
            z-index: 3;
            transform: none;

            .rtl & {
              right: auto;
              left: 10px;
            }

            .swiper-pagination-bullet {
              margin: 0 2px;
              width: 6px;
              height: 6px;
              border: 1px solid #28374b;
              border-radius: 50%;
              background-color: transparent;
              opacity: 1;

              &.swiper-pagination-bullet-active {
                background-color: #28374b;
              }
            }
          }
        }

        .product-details {
          flex: 1 1 auto;
          padding: 16px 20px 0;

          .brand-logo {
            margin-right: 20px;
            width: 50px;
            height: 50px;
            object-fit: contain;
            object-position: left center;

            .rtl & {
              margin-right: auto;
              margin-left: 20px;
            }
          }

          .title {
            margin-bottom: 0;
            color: $headings-color;
            font-size: 16px;
            font-weight: 600;
            line-height: 120%;
          }

          .price {
            display: flex;
            align-items: center;
            margin-top: 14px;
            margin-bottom: 16px;
            color: var(--secondary-store);
            font-size: 20px;
            font-weight: 600;

            .previous-price {
              margin-left: 15px;
              color: #b0b5bf;
              font-weight: 400;
              font-size: 80%;
              text-decoration: line-through;

              .rtl & {
                margin-left: unset;
                margin-right: 15px;
              }
            }
          }

          .model-variants {
            margin-bottom: 15px;
            .model-variant {
              width: 75px;
              height: 75px;
              border-radius: 6px;
              margin-right: 5px;
              margin-bottom: 5px;
              overflow: hidden;

              .rtl & {
                margin-right: unset;
                margin-left: 5px;
              }

              .model-image {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
                background-color: #fff;
              }
            }
          }

          .variants {
            display: flex;
            flex-wrap: wrap;
            margin-right: -20px;
            width: auto;
            flex-direction: row;
            gap: 0;
            .rtl & {
              margin-right: unset;
              margin-left: -20px;
            }

            .variant {
              display: flex;
              align-items: center;
              flex: 0 0 calc(50% - 20px);
              margin-bottom: 16px;
              margin-right: 20px;
              color: #000;
              font-size: 13px;
              font-weight: 300;

              .rtl & {
                margin-right: unset;
                margin-left: 20px;
              }

              .dropdown {
                flex-grow: 1;
                margin-left: 5px;

                .rtl & {
                  margin-left: unset;
                  margin-right: 5px;
                }

                .dropdown-toggle {
                  width: 100%;
                }
              }
            }
            .variant-color {
              margin-bottom: 16px;
            }
          }
        }

        .title {
          margin-bottom: 5px;
          color: #58575a;
          font-size: 16px;
          line-height: 134%;
          font-weight: 600;
        }

        .product-customization {
          margin-top: 10px;
          margin-bottom: 20px;
          padding: 0 20px;

          .customization-text {
            p {
              font-size: 12px;
            }
          }
          .customization-form {
            &.customization-form-image {
              .btn-remove {
                svg {
                  width: 12px;
                  height: 12px;
                }
              }
            }

            label {
              display: block;
              font-size: 14px;
            }

            .customization-label-input {
              font-size: 14px;
              font-weight: 500;
              &.upto-10 {
                width: 120px;
              }
              &.upto-20 {
                width: 240px;
              }
            }

            .hint-text {
              font-size: 10px;
            }
          }
        }
        .add-to-cart-container {
          display: flex;
          justify-content: space-around;
          position: fixed;
          z-index: 999;
          bottom: 0px;
          background: white;
          height: 12%;
        }
        .buttons {
          display: flex;
          align-items: center;
          margin-top: 10px;
          padding: 0 20px;
          width: 100%;
          justify-content: space-around;
          position: fixed;
          z-index: 999;
          bottom: 0px;
          background: white;
          height: 12%;

          .btn-add-to-cart {
            flex-grow: 1;
            margin-right: 20px;

            .rtl & {
              margin-right: unset;
              margin-left: 20px;
            }
          }

          .btn-share {
            width: 45px;
            padding: 0;
            margin-right: 0;

            .rtl & {
              margin-right: unset;
              margin-left: 0;
            }

            span {
              display: none;
            }

            img {
              margin: 0;
              width: 20px;
              height: 20px;
            }
          }
        }
        .mobile-item-description {
          margin-bottom: 10%;
        }
        .product-description {
          padding: 0 20px;
          font-size: 14px;
        }

        .extra-info {
          margin-bottom: 32%;
          margin-top: 30px;
          padding: 0 20px;
          a,
          button {
            display: block;
            margin-bottom: 20px;
            color: #000000;
            font-size: 12px;
            img {
              margin-right: 10px;
              width: 15px;
              height: 15px;

              .rtl & {
                margin-right: unset;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
