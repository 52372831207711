.embedded-session-timer {
  width: 100%;
  .timer-app {
    height: 35px !important;
    .sessionPreview-timer-numbers {
      height: 12px !important;
      width: 12px !important;
      font-size: 11px !important;
    }
    .time-unit {
      font-size: 6px !important;
      height: 9px !important;
    }
  }
}
