//////////////////////////////////////////
// Camera Feed Mobile
//////////////////////////////////////////
.camera-feed-wrapper-mobile {
  position: relative;
  align-self: flex-start;
  height: 100%;
  width: 100%;
  overflow: hidden;
  // position: fixed;
  .camera-placeholder-video {
    width: 100%;
    height: 100%;
  }
  .camera-feed {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .bottom-gradient,
  .top-gradient {
    position: absolute;
    z-index: 2;
    height: 150px;
    width: 100%;
  }
  .top-gradient {
    top: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 1.51%, rgba(0, 0, 0, 0) 63.7%),
      linear-gradient(0deg, rgba(0, 0, 0, 0) 42.21%, rgba(0, 0, 0, 0.3) 100%);
  }
  .bottom-gradient {
    bottom: 0;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  }
}

.promotion-video-player {
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
